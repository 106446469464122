import { Injectable } from '@angular/core';
import { TimeService } from '../app/services/time-service.interface';

@Injectable({
  providedIn: 'root',
})
export class BogusTimeService implements TimeService {
  private delta = 0;

  getPastDates(daysBack: number): Date[] {
    const dates: Date[] = [];

    for (let i = 0; i < daysBack; i++) {
      dates.push(new Date(this.now() - i * 24 * 60 * 60 * 1000));
    }

    return dates.reverse();
  }

  now(): number {
    return Date.now() + this.delta;
  }

  addMinutes(minutes: number): void {
    this.delta += minutes * 60 * 1000;
  }

  reset(): void {
    this.delta = 0;
  }
  addHours(hours: number): void {
    this.delta += hours * 60 * 60 * 1000;
  }

  addSeconds(seconds: number): void {
    this.delta += seconds * 1000;
  }

  getDelta(): number {
    return this.delta;
  }
}
