/* eslint-disable max-len */
import { LayoutModule } from '@angular/cdk/layout';
import {
  CommonModule,
  DatePipe,
  registerLocaleData,
  TitleCasePipe,
} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LetModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as echarts from 'echarts/core';
import { environment } from 'environments/environment';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppMaterialModule } from 'src/app/app-material.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { ImportComponent } from 'src/app/components/import/import.component';
import { InteractionComponent } from 'src/app/components/interaction/interaction.component';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { NavComponent } from 'src/app/components/nav/nav.component';
import { SearchWidgetComponent } from 'src/app/components/search-widget/search-widget.component';
import { TeacherDashboardScoreIndicatorComponent } from 'src/app/components/teacher-dashboard-score-indicator/teacher-dashboard-score-indicator.component';
import { TeacherDashboardComponent } from 'src/app/components/teacher-dashboard/teacher-dashboard.component';
import { metaReducers, reducers } from 'src/app/store/reducers';
import { firebaseFunctionsRegion } from '../../shared/region';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AdminComponent } from './components/admin/admin.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonWithSpinnerComponent } from './components/button-with-spinner/button-with-spinner.component';
import {
  CalculatorComponent,
  CalculatorDialogComponent,
} from './components/calculator';
import { ChooseGroupsComponent } from './components/choose-groups/choose-groups.component';
import { ChooseOrganizationComponent } from './components/choose-organization/choose-organization.component';
import { ChooseTeachersComponent } from './components/choose-teachers/choose-teachers.component';
import { CodeInputDialogComponent } from './components/code-input-dialog/code-input-dialog.component';
import { ContextComponent } from './components/context/context.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { DeleteUserScoresComponent } from './components/delete-user-scores/delete-user-scores.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ExamFinishedComponent } from './components/exams/exam-finished/exam-finished.component';
import { ExamOverviewStudentComponent } from './components/exams/exam-overview-student/exam-overview-student.component';
import { ExamOverviewTeacherComponent } from './components/exams/exam-overview-teacher/exam-overview-teacher.component';
import { ExamResultsTeacherComponent } from './components/exams/exam-results-teacher/exam-results-teacher.component';
import { ExamSessionSubComponent } from './components/exams/exam-session/exam-session-sub/exam-session-sub.component';
import { ExamSessionComponent } from './components/exams/exam-session/exam-session.component';
import { ReadyExamComponent } from './components/exams/ready-exam/ready-exam.component';
import { ExportConvertorComponent } from './components/export-convertor/export-convertor.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { GroupsComponent } from './components/groups/groups.component';
import { LicenseExpiredComponent } from './components/license-expired/license-expired.component';
import { LicensesComponent } from './components/licenses/licenses.component';
import { LoginComponent } from './components/login/login.component';
import { NotepadComponent, NotepadDialogComponent } from './components/notepad';
import { OrganizationComponent } from './components/organization/organization.component';
import { PointSpotComponent } from './components/point-spot/point-spot.component';
import { ProgressAreaComponent } from './components/progress-area/progress-area.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { SetGroupDialogComponent } from './components/set-group-dialog/set-group-dialog.component';
import { SetRolesComponent } from './components/set-roles/set-roles.component';
import { StudentDetailComponent } from './components/student-detail/student-detail.component';
import { StudentUnitScoreDialogComponent } from './components/student-unit-score-dialog/student-unit-score-dialog.component';
import { StudentUnitScoreComponent } from './components/student-unit-score/student-unit-score.component';
import { TimeTravelDialogComponent } from './components/testing/time-travel-dialog/time-travel-dialog.component';
import { UnitLocationTrackerComponent } from './components/unit-location-tracker/unit-location-tracker.component';
import { UnitComponent } from './components/unit/unit.component';
import { UserProjectAccessDialogComponent } from './components/user-project-access-dialog/user-project-access-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { CustomErrorStateMatcherDirective } from './custom-error-state-matcher.directive';
import { LinkedDatepickersDirective } from './directives/linked-datepickers.directive';
import { initEcharts } from './misc/app-echarts';
import { ArrayFillerPipe } from './misc/array-filler.pipe';
import { HighlightTeacherDashboardColumnDirective } from './misc/highlight-teacher-dashboard-column.directive';
import { InteractionScoreTextPipe } from './misc/interaction-score-text.pipe';
import { OrganizationPipe } from './misc/organisation.pipe';
import { PadZeroPipe } from './misc/pad-zero.pipe';
import { SafeHtmlPipe } from './misc/safe-html.pipe';
import { SafeResourcePipe } from './misc/safe-resource-url.pipe';
import { SelectOnFocusDirective } from './misc/select-on-focus.directive';
import { SplitCamelCasePipe } from './misc/split-camel-case.pipe';
import { TruncatePipe } from './misc/truncate.pipe';
import { TruthyPipe } from './misc/truthy.pipe';
import { UsernamePipe } from './misc/username.pipe';
import { LocalTimeService } from './services/local-time.service';
import { TIME_SERVICE } from './services/time-service.token';
import { Effects } from './store';

registerLocaleData(localeNl);

const isLocalhost = window.location.hostname === 'localhost';

@NgModule({
  declarations: [
    AccountSettingsComponent,
    AdminComponent,
    AppComponent,
    ArrayFillerPipe,
    BreadcrumbComponent,
    ButtonWithSpinnerComponent,
    CalculatorComponent,
    CalculatorDialogComponent,
    ChooseGroupsComponent,
    ChooseOrganizationComponent,
    ChooseTeachersComponent,
    CodeInputDialogComponent,
    ContextComponent,
    CountdownComponent,
    CustomErrorStateMatcherDirective,
    DeleteUserScoresComponent,
    DialogComponent,
    ExamFinishedComponent,
    ExamOverviewStudentComponent,
    ExamOverviewTeacherComponent,
    ExamResultsTeacherComponent,
    ExamSessionComponent,
    ExamSessionSubComponent,
    ExportConvertorComponent,
    FeedbackComponent,
    GroupsComponent,
    HighlightTeacherDashboardColumnDirective,
    ImportComponent,
    InteractionComponent,
    InteractionScoreTextPipe,
    LicenseExpiredComponent,
    LicensesComponent,
    LinkedDatepickersDirective,
    LoginComponent,
    MenuComponent,
    NavComponent,
    NotepadComponent,
    NotepadDialogComponent,
    OrganizationComponent,
    OrganizationPipe,
    PadZeroPipe,
    PointSpotComponent,
    ProgressAreaComponent,
    ProgressIndicatorComponent,
    ReadyExamComponent,
    SafeHtmlPipe,
    SafeResourcePipe,
    SearchWidgetComponent,
    SelectOnFocusDirective,
    SetGroupDialogComponent,
    SetRolesComponent,
    SplitCamelCasePipe,
    StudentDetailComponent,
    StudentUnitScoreComponent,
    StudentUnitScoreDialogComponent,
    TeacherDashboardComponent,
    TeacherDashboardScoreIndicatorComponent,
    TimeTravelDialogComponent,
    TruncatePipe,
    TruthyPipe,
    UnitComponent,
    UnitLocationTrackerComponent,
    UsernamePipe,
    UserProjectAccessDialogComponent,
    UsersComponent,
  ],
  imports: [
    AppMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    EffectsModule.forRoot(Effects.ALL),
    FormsModule,
    LayoutModule,
    NgrxFormsModule,
    LetModule,
    NgxEchartsModule.forRoot({
      echarts: initEcharts(echarts),
    }),
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 30 })
      : [],
    StoreRouterConnectingModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() =>
      initializeFirestore(getApp(), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      })
    ),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), firebaseFunctionsRegion);
      if (isLocalhost) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideStorage(() => getStorage()),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    DatePipe,
    TitleCasePipe,
    UsernamePipe,
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: TIME_SERVICE, useExisting: LocalTimeService },
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: isLocalhost ? ['localhost', 9099] : undefined,
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: isLocalhost ? ['localhost', 8080] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
