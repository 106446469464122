import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { selectUserHasAppAccess } from 'src/app/store/reducers/user.reducer';

export const licenseGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.pipe(
    select(selectUserHasAppAccess),
    switchMap((licensed) => {
      if (licensed) {
        return of(true);
      }
      return timer(2000).pipe(map(() => router.parseUrl('license-expired')));
    }),
    first()
  );
};
