/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AlgoliaService } from '../../services/algolia.service';

export interface SearchHit {
  objectID: string;
  name?: string;
}

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss'],
})
export class SearchWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @Output() public selected = new EventEmitter<SearchHit>();
  @Output() public queryChanged = new EventEmitter<string>();
  @Input() public type: 'organizations' | 'users';
  @Input() public label = 'Search';
  @Input() public placeholder = '';
  @Input() public required = false;
  @Input() public showHits = true;
  @Input() public filters = '';
  @Input() public displayFn: (hit: SearchHit) => string = (hit) =>
    hit.name || hit.objectID;

  public searchControl = new FormControl('');
  public hits: SearchHit[] = [];
  public loading$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new ReplaySubject<boolean>(1);

  constructor(private algoliaService: AlgoliaService) {}

  displayWithFn = (value: SearchHit | string | null): string => {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    return this.displayFn(value);
  };

  public ngOnInit(): void {
    // Handle search input
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(async (value) => {
        this.queryChanged.emit(value);
        // When using mat-autocomplete, the form control's value will be:
        // - a string when typing (the search query)
        // - an object when selecting from the dropdown (the selected hit)
        // We only want to search when it's a string, as the object case means
        // an option was selected and we don't need to search again
        if (!value || typeof value !== 'string') {
          this.hits = [];
          return;
        }

        const spinnerDelay = setTimeout(() => {
          this.loading$.next(true);
        }, 700);

        try {
          this.hits = await this.algoliaService.search(this.type, value);
        } finally {
          clearTimeout(spinnerDelay);
          this.loading$.next(false);
        }
      });

    // Subscribe to loading state
    this.algoliaService
      .isLoading(this.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.loading$);
  }

  public selectHit(hit: SearchHit): void {
    this.selected.emit(hit);
    this.searchControl.setValue(this.displayFn(hit), { emitEvent: false });
    this.hits = [];
  }

  public clear(): void {
    this.searchControl.setValue('');
    this.hits = [];
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
