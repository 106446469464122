import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { ChooseGroupsComponent } from 'src/app/components/choose-groups/choose-groups.component';
import { TimeService } from 'src/app/services/time-service.interface';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { AppState } from 'src/app/store/reducers';
import {
  getDatePartFromISOString,
  msToHoursMinutes,
  msToMinutes,
} from '../../../../shared/helpers/time-helper';
import { AppUser, DbUser, UserReference } from '../../../../shared/models/user';
import { DatabaseService } from '../../services/database.service';
import { selectUser } from '../../store/reducers/user.reducer';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss'],
})
export class StudentDetailComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  targetUser$: Observable<DbUser>;
  teachers$: Observable<string[]>;
  groups$: Observable<string[]>;
  options: EChartsOption;
  currentUser$: Observable<AppUser>;
  isFollowing$: Observable<boolean>;
  loading = false;

  constructor(
    private databaseService: DatabaseService,
    private store: Store<AppState>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public userRef: UserReference,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  ngOnInit() {
    this.currentUser$ = this.store.select(selectUser);
    this.targetUser$ = this.databaseService
      .getUser(this.userRef.uid)
      .pipe(takeUntil(this.ngDestroyed$));
    this.teachers$ = this.targetUser$.pipe(
      map((user) => user.teachers?.map((teacher) => teacher.name))
    );
    this.groups$ = this.targetUser$.pipe(
      map((user) => user.groups?.map((group) => group.name))
    );

    this.isFollowing$ = combineLatest([
      this.currentUser$,
      this.targetUser$,
    ]).pipe(
      map(
        ([currentUser, targetUser]) =>
          !!targetUser?.teachers?.some(
            (teacher) => teacher.uid === currentUser?.uid
          )
      )
    );

    // Build time chart
    const daysBack = 31;
    this.databaseService
      .getAttemptTimePerDayForUser(this.userRef.uid, daysBack)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data) => {
        // Merge results with dates of last seven days. Default to 0.
        const dates = this.timeService.getPastDates(daysBack);
        // TODO this is probably prone to daylight savings time issues
        const xAxisData = dates.map((date) =>
          date.toLocaleDateString('nl-NL', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
          })
        );
        const seriesData = dates.map((date) => {
          const result = data.find(
            (item) => item.date === getDatePartFromISOString(date.toISOString())
          );
          return result ? result.milliseconds : 0;
        });

        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            valueFormatter: (value) => `${msToMinutes(+value)}`,
          },
          xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              rotate: 45,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value: number) => `${msToHoursMinutes(value)}`,
            },
          },
          series: [
            {
              name: 'Minuten',
              data: seriesData,
              type: 'bar',
            },
          ],
        };
      });
  }

  followStudent() {
    combineLatest([this.currentUser$, this.targetUser$])
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe(([currentUser, targetUser]) => {
        this.loading = true;
        this.databaseService
          .addTeacherToUser({
            teacherUid: currentUser.uid,
            targetUid: targetUser.uid,
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }

  unfollowStudent() {
    combineLatest([this.currentUser$, this.targetUser$])
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe(([currentUser, targetUser]) => {
        this.loading = true;
        this.databaseService
          .removeTeacherFromUser({
            teacherUid: currentUser.uid,
            targetUid: targetUser.uid,
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }

  openChooseGroupsDialog() {
    this.dialog.open(ChooseGroupsComponent, {
      data: this.userRef.uid,
    });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
