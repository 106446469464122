import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of, timer } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export const authGuard = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const auth = inject(AuthService);

  return auth.authState().pipe(
    switchMap((user) => {
      if (user) {
        return of(true);
      }
      return timer(1000).pipe(
        map(() => {
          if (state.url && state.url !== '/') {
            router.navigate(['login'], {
              queryParams: { returnUrl: state.url },
            });
          } else {
            router.navigate(['login']);
          }
          return false;
        })
      );
    }),
    first()
  );
};
