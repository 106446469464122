import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Observable, of } from 'rxjs';
import { Unit } from 'shared/models/unit';
import { UserUnitScore } from 'shared/models/user-unit-score';
import { constants } from 'shared/constants';

export type ScoreWithName = {
  name: string;
  score: UserUnitScore;
};

@Component({
  selector: 'app-student-unit-score-dialog',
  templateUrl: './student-unit-score-dialog.component.html',
  styleUrls: ['./student-unit-score-dialog.component.scss'],
})
export class StudentUnitScoreDialogComponent implements OnInit {
  unit$: Observable<Unit>;
  userUnitScores$: Observable<ScoreWithName[]>;
  displayedColumns: string[] = ['Name', 'Date', 'Score'];
  toObservable = of;
  dateFormat = constants.longDateTimeFormat;

  constructor(
    public dialogRef: MatDialogRef<StudentUnitScoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userUnitScores: ScoreWithName[];
      unit: Unit;
    }
  ) {}

  ngOnInit() {
    this.unit$ = of(this.data.unit);
    this.userUnitScores$ = of(this.data.userUnitScores);
  }
}
