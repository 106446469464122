import { Inject, Injectable } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  QueryConstraint,
  collection,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  EnterLicenseResult,
  GenerateLicensePayload,
  MarkLicensesAsDistributedPayload,
} from 'functions/src/models';
import { Observable } from 'rxjs';
import { constants } from 'shared/constants';
import { License, LicenseDurationType } from '../../../shared/models/license';
import { SearchService } from '../types/search-service';
import {
  PaginatedResponse,
  Pagination,
  PaginationService,
} from './pagination.service';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

export type LicenseFilter = {
  code?: License['code'];
  type?: License['type'];
  durationType?: LicenseDurationType;
  organizationId?: License['organizationId'];
  distributionChannel?: License['distributionChannel'];
  assignedTo?: License['assignedTo'];
  generatedAtStart?: License['generatedAt'];
  generatedAtEnd?: License['generatedAt'];
  distributedAtStart?: License['distributedAt'];
  distributedAtEnd?: License['distributedAt'];
};

@Injectable({
  providedIn: 'root',
})
export class LicenseService implements SearchService<LicenseFilter, License> {
  searchItems: (
    filter: LicenseFilter,
    pagination: Pagination
  ) => Observable<PaginatedResponse<License>> = this.searchLicenses.bind(this);

  constructor(
    private firestore: Firestore,
    private functions: Functions,
    private paginationService: PaginationService,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  async generateLicenses(config: GenerateLicensePayload): Promise<License[]> {
    const generateLicensesFn = httpsCallable<GenerateLicensePayload, License[]>(
      this.functions,
      'license-generateLicenses'
    );
    const result = await generateLicensesFn(config);
    return result.data;
  }

  async enterLicenseCode(code: string): Promise<EnterLicenseResult> {
    const enterLicenseCodeFn = httpsCallable<string, EnterLicenseResult>(
      this.functions,
      'license-enterLicenseCode'
    );
    const result = await enterLicenseCodeFn(code);
    return result.data;
  }

  async markLicensesAsDistributed(
    payload: MarkLicensesAsDistributedPayload
  ): Promise<License['distributedAt']> {
    const markLicensesAsDistributedFn = httpsCallable<
      MarkLicensesAsDistributedPayload,
      License['distributedAt']
    >(this.functions, 'license-markLicensesAsDistributed');
    const result = await markLicensesAsDistributedFn(payload);
    return result.data;
  }

  searchLicenses(
    licenseFilter?: LicenseFilter,
    pagination?: Pagination
  ): Observable<PaginatedResponse<License>> {
    const buildQuery = (ref: CollectionReference<License>) => {
      const constraints: QueryConstraint[] = [];

      if (licenseFilter?.code) {
        constraints.push(where('code', '==', licenseFilter.code));
      }

      if (licenseFilter?.type) {
        constraints.push(where('type', '==', licenseFilter.type));
      }

      if (licenseFilter?.durationType) {
        constraints.push(
          where('durationType', '==', licenseFilter.durationType)
        );
      }

      if (licenseFilter?.organizationId) {
        constraints.push(
          where('organizationId', '==', licenseFilter.organizationId)
        );
      }

      if (licenseFilter?.distributionChannel) {
        constraints.push(
          where('distributionChannel', '==', licenseFilter.distributionChannel)
        );
      }

      if (licenseFilter?.assignedTo) {
        constraints.push(where('assignedTo', '==', licenseFilter.assignedTo));
      }

      if (licenseFilter?.generatedAtStart) {
        constraints.push(
          where('generatedAt', '>=', licenseFilter.generatedAtStart)
        );
      }

      if (licenseFilter?.generatedAtEnd) {
        constraints.push(
          where('generatedAt', '<=', licenseFilter.generatedAtEnd)
        );
      }

      if (licenseFilter?.distributedAtStart) {
        constraints.push(
          where('distributedAt', '>=', licenseFilter.distributedAtStart)
        );
      }

      if (licenseFilter?.distributedAtEnd) {
        constraints.push(
          where('distributedAt', '<=', licenseFilter.distributedAtEnd)
        );
      }

      // Default ordering
      constraints.push(orderBy('generatedAt', 'desc'));

      return query(ref, ...constraints);
    };

    const licensesRef = collection(
      this.firestore,
      constants.dbCollections.licenses
    ) as CollectionReference<License>;
    const baseQuery = buildQuery(licensesRef);

    return this.paginationService.getPage<License>({
      queryName: 'licenses-search',
      queryFn: baseQuery,
      pagination,
    });
  }
}
