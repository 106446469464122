<mat-card>
    <mat-tab-group>
        <mat-tab label="Gemaakte toetsen">
            <mat-card-content>
                <div appLinkedDatepickers [startDate]="startDate" [endDate]="endDate" class="filter-container">
                    <mat-form-field class="datepicker filter" appearance="outline">
                        <mat-label>Van</mat-label>
                        <input autocomplete="off" matInput [formControl]="startDate" [matDatepicker]="pickerStart">
                        <mat-datepicker-toggle *ngIf="startDate.value" matSuffix (click)="startDate.setValue(null)">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="datepicker filter" appearance="outline">
                        <mat-label>Tot en met</mat-label>
                        <input autocomplete="off" matInput [formControl]="endDate" [matDatepicker]="pickerEnd">
                        <mat-datepicker-toggle *ngIf="endDate.value" matSuffix (click)="endDate.setValue(null)">
                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                    <mat-checkbox [formControl]="myExamsOnly">Mijn toetsen</mat-checkbox>
                    <app-button-with-spinner (click)="search()" [isLoading]="isLoading$ | async">
                        Zoeken
                    </app-button-with-spinner>
                </div>

                <mat-table [dataSource]="examResults$ | async">
                    <ng-container *ngFor="let column of examResultColumns" [matColumnDef]="column.field">
                        <mat-header-cell *matHeaderCellDef>{{ column.title }}</mat-header-cell>
                        <mat-cell *matCellDef="let examInstance">

                            <ng-container *ngIf="column.type === 'date'">
                                {{ examInstance[column.field] | date: 'dd-MM-yyyy' }}
                            </ng-container>

                            <ng-container *ngIf="column.type === 'user'">
                                {{ examInstance[column.field] | username | async}}
                            </ng-container>

                            <ng-container *ngIf="column.type === undefined">
                                {{ examInstance[column.field] }}
                            </ng-container>
                            <ng-container *ngIf="column.type === 'duration'">
                                {{ examInstance[column.field] | date: 'HH:mm': "UTC"}}
                            </ng-container>
                            <ng-container *ngIf="column.type === 'actions'">
                                <a *ngIf="examInstance.finishedExamSessions > 0" mat-stroked-button
                                    [routerLink]="['/exam-results', examInstance.id]">Inzien</a>
                            </ng-container>

                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="progressBar">
                        <mat-header-cell *matHeaderCellDef colspan="4" class="progress-bar-cell">
                            <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"
                                color="accent"></mat-progress-bar>
                        </mat-header-cell>
                    </ng-container>

                    <mat-header-row mat-header-row *matHeaderRowDef="examResultDisplayedColumns; sticky: true"
                        class="main-header">
                    </mat-header-row>
                    <mat-header-row *matHeaderRowDef="['progressBar']; sticky: true" class="progress-bar-row">
                    </mat-header-row>
                    <mat-row mat-row *matRowDef="let row; columns: examResultDisplayedColumns;"></mat-row>
                </mat-table>
                <div *ngIf="(examResults$ | async)?.length === 0" class="placeholder-text">
                    Geen gemaakte toetsen gevonden.
                </div>
            </mat-card-content>
        </mat-tab>
        <mat-tab label="Toetsen klaarzetten">
            <mat-card-content>
                <mat-table [dataSource]="exams$ | async">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>Toets</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{ row.name }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Acties</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <a mat-stroked-button [routerLink]="['/ready-exam', row.id]">Klaarzetten</a>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row mat-header-row
                        *matHeaderRowDef="examDisplayedColumns; sticky: true"></mat-header-row>
                    <mat-row mat-row *matRowDef="let row; columns: examDisplayedColumns;"></mat-row>
                </mat-table>
                <div *ngIf="(exams$ | async)?.length === 0" class="placeholder-text">
                    Geen toetsen beschikbaar.
                </div>
            </mat-card-content>
        </mat-tab>
    </mat-tab-group>
</mat-card>