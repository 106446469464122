<div class="filter-container">
    <app-search-widget class="org-filter" type="organizations" label="School" [displayFn]="displayOrgHit"
        (selected)="filterOrg($event)" (queryChanged)="orgInputChanged($event)">
    </app-search-widget>

    <app-search-widget class="user-filter" type="users" label="Gebruiker" (selected)="filterUser($event)"
        (queryChanged)="userInputChanged($event)">
    </app-search-widget>

    <div class="mat-form-field-wrapper">
        <button mat-raised-button color="primary" (click)="search()">
            Search<mat-icon>search</mat-icon>
        </button>
    </div>

    <div class="mat-form-field-wrapper download-btn">
        <button mat-flat-button (click)="downloadCSV()">
            <mat-icon>file_download</mat-icon>
        </button>
    </div>
</div>

<mat-table [dataSource]="userDataSource">
    <ng-container matColumnDef="uid">
        <mat-header-cell *matHeaderCellDef>UID</mat-header-cell>
        <mat-cell *matCellDef="let user">
            <span class="copy pointer" matRipple (click)="copyUidToClipboard(user.uid)" [title]="user.uid">⧉</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ user.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ user.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="roles">
        <mat-header-cell *matHeaderCellDef>Rollen</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('roles', user) }}
            <button mat-icon-button (click)="setUserRoles(user.uid)">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>School</mat-header-cell>
        <mat-cell *matCellDef="let user">
            <span *ngIf="user.organizationId | organization | async as org"
                [title]="'ID: ' + org?.id + ' | Adres: ' + org.address">
                {{ org?.name }}
            </span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="organizationHardLink">
        <mat-header-cell *matHeaderCellDef>HardLink</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('organizationHardLink', user) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="teachers">
        <mat-header-cell *matHeaderCellDef>Leraren</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('teachers', user) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="groups">
        <mat-header-cell *matHeaderCellDef>Groepen</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('groups', user) }}
            <button mat-icon-button (click)="setUserGroups(user.uid)">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="projectAccess">
        <mat-header-cell *matHeaderCellDef>Project toegang</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('projectAccess', user)}}
            <button mat-icon-button (click)="setProjectAccess(user.uid)">
                <mat-icon>edit</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef>Aangemaakt</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('createdDate', user) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastSeen">
        <mat-header-cell *matHeaderCellDef>Laatst gezien</mat-header-cell>
        <mat-cell *matCellDef="let user">{{ displayCell('lastSeen', user) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="moreActions">
        <mat-header-cell *matHeaderCellDef>Meer</mat-header-cell>
        <mat-cell *matCellDef="let user">
            <button mat-icon-button [matMenuTriggerFor]="moreActionsMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreActionsMenu="matMenu">
                <button mat-menu-item (click)="deleteScoresForUser(user.uid)">Delete scores</button>
                <button mat-menu-item (click)="impersonateUser(user.uid)">Log in als gebruiker</button>
            </mat-menu>
        </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #userPaginator [hidePageSize]="true" pageSize="50"></mat-paginator>