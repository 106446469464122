import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { selectExamSessionId } from 'src/app/store/reducers/exam.reducer';
import { examActions } from '../../actions/exam.actions';
import { ComponentNames } from '../../component-names.enum';
import { selectActiveComponents } from '../../reducers/shared.reducer';

@Injectable()
export class ExamSessionSubComponentEffects {
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly store = inject(Store<AppState>);

  onExamStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(examActions.examSessionStarted),
        withLatestFrom(
          this.store.select(selectExamSessionId),
          this.store.select(selectActiveComponents)
        ),
        filter(
          ([, examSessionId, activeComponents]) =>
            activeComponents.includes(ComponentNames.examSessionSubComponent) &&
            !!examSessionId
        ),
        tap(() =>
          this.router.navigate(['0', '0'], {
            relativeTo: this.router.routerState.root.firstChild,
          })
        )
      ),
    { dispatch: false }
  );
}
