import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { getRoleName } from '../../../shared/helpers/get-role-name';
import { showSnackbar } from '../store/actions/snackbar.actions';
import {
  selectIsDbUserLoaded,
  selectUserRoles,
} from '../store/reducers/user.reducer';
import { RouteData } from '../types/route-data';

export const roleGuard = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(selectIsDbUserLoaded).pipe(
    filter((isLoaded) => isLoaded),
    switchMap(() => store.select(selectUserRoles)),
    map((roles) => {
      const permittedRoles = (route.data as RouteData).roles;
      if (
        roles &&
        Array.from(roles.values()).some((role) => permittedRoles.has(role))
      ) {
        return true;
      }

      store.dispatch(
        showSnackbar({
          message: `Hiervoor heb je één van deze rollen nodig: ${Array.from(
            permittedRoles
          )
            .map((role) => getRoleName(role))
            .join(', ')}`,
          action: 'Ok',
        })
      );
      router.navigate(['/']);
      return false;
    }),
    first()
  );
};
