import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { constants } from 'shared/constants';
import { Interaction } from 'shared/models/interaction';
import { AppUser } from 'shared/models/user';
import {
  DialogComponent,
  DialogData,
  DialogPreset,
} from '../dialog/dialog.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  @Input() parsedInteraction$: Observable<Interaction>;
  @Input() user$: Observable<AppUser>;

  constructor(private dialog: MatDialog) {}

  async sendFeedback() {
    const result = await this.dialog
      .open<DialogComponent, DialogData>(DialogComponent, {
        data: {
          title: 'Feedback',
          text:
            'Klik op OK om via email een opmerking te maken of een storing te melden.\n' +
            'Er zullen al wat technische gegevens ingevuld zijn.',
          preset: DialogPreset.okCancel,
        },
      })
      .afterClosed()
      .pipe(first())
      .toPromise();

    if (result) {
      combineLatest([this.parsedInteraction$, this.user$])
        .pipe(first())
        .subscribe(([parsedInteraction, user]) => {
          const lineBreak = '%0D%0A';
          const mailto = [
            `mailto:${constants.supportEmail}?subject=Feedback ffRekenen #${parsedInteraction.id}&body=`,
            `Beste support,`,
            lineBreak,
            lineBreak,
            `Graag wil ik de volgende feedback melden:`,
            lineBreak,
            lineBreak,
            lineBreak,
            `Screenshots eventueel bijvoegen als bijlage.`,
            lineBreak,
            lineBreak,
            `Interactie ID: ${parsedInteraction.id}`,
            lineBreak,
            `UID: ${user?.uid}`,
            lineBreak,
            `URL: ${window.location.href}`,
            lineBreak,
            lineBreak,
          ].join('');

          const mailtoLink = document.createElement('a');
          mailtoLink.setAttribute('href', mailto);
          mailtoLink.setAttribute('target', '_blank');
          mailtoLink.setAttribute('rel', 'noopener noreferrer');
          mailtoLink.click();
        });
    }
  }
}
