import { DatePipe, TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { getRoleName } from '../../../../shared/helpers/get-role-name';
import { getDateAndTimeUpToSecondsFromISOString } from '../../../../shared/helpers/time-helper';
import { Organization } from '../../../../shared/models/organization';
import { AppUser, DbUser } from '../../../../shared/models/user';
import { downloadCSV } from '../../helpers/download-helper';
import { DatabaseService, UserFilter } from '../../services/database.service';
import { UserService } from '../../services/user.service';
import { userActions } from '../../store/actions/user.actions';
import { AppState } from '../../store/reducers';
import { ChooseGroupsComponent } from '../choose-groups/choose-groups.component';
import { DeleteUserScoresComponent } from '../delete-user-scores/delete-user-scores.component';
import { DialogPreset } from '../dialog/dialog.component';
import { SearchHit } from '../search-widget/search-widget.component';
import { SetRolesComponent } from '../set-roles/set-roles.component';
import { UserProjectAccessDialogComponent } from '../user-project-access-dialog/user-project-access-dialog.component';
import { UsersDataSource } from './users-datasource';

type MappedUser = DbUser & {
  organization?: Observable<Organization>;
};

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('userPaginator') userPaginator: MatPaginator;
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  users$: Observable<MappedUser[]>;
  displayedColumns = [
    'uid',
    'name',
    'email',
    'roles',
    'organization',
    'organizationHardLink',
    'teachers',
    'groups',
    'projectAccess',
    'createdDate',
    'lastSeen',
    'moreActions',
  ];

  selectedOrganizationId$: BehaviorSubject<Organization['id']> =
    new BehaviorSubject(null);
  selectedUserId$: BehaviorSubject<AppUser['uid']> = new BehaviorSubject(null);
  search$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  orderByField: UserFilter['orderBy'] = 'createdDate';
  userDataSource: UsersDataSource;

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe,
    private userService: UserService
  ) {}

  ngOnInit() {
    // Filtered users
    this.users$ = combineLatest([
      this.selectedOrganizationId$,
      this.selectedUserId$,
      this.search$,
    ]).pipe(
      filter(([selectedOrganizationId, selectedUserId, search]) => !!search),
      switchMap(([organizationId, selectedUid]) => {
        // User filter takes precedence
        let userFilter: UserFilter;

        if (selectedUid) {
          userFilter = {
            uids: [selectedUid],
          };
        } else {
          userFilter = {
            organizationId,
            orderBy: this.orderByField,
            orderDirection: 'desc',
          };
        }
        this.userDataSource.loadFirstPage(userFilter);
        return this.userDataSource.connect();
      }),
      takeUntil(this.ngDestroyed$)
    );

    this.userDataSource = new UsersDataSource(this.userService);
  }

  ngAfterViewInit() {
    this.userDataSource.internalPaginator = this.userPaginator;
  }

  displayOrg(organization: Organization): string {
    if (organization) {
      return (
        organization.name +
        (organization.city ? ' (' + organization.city + ')' : '')
      );
    }

    return '';
  }

  displayOrgHit(hit: SearchHit): string {
    return hit.name || hit.objectID;
  }

  orgInputChanged(text: string) {
    if (!text) {
      this.search$.next(false);
      this.selectedOrganizationId$.next(null);
    }
  }

  filterOrg(hit: SearchHit) {
    this.selectedOrganizationId$.next(hit.objectID);
    this.search$.next(true);
  }

  userInputChanged(text: string) {
    if (!text) {
      this.search$.next(false);
      this.selectedUserId$.next(null);
    }
  }

  filterUser(hit: SearchHit) {
    this.selectedUserId$.next(hit.objectID);
    this.search$.next(true);
  }

  search() {
    this.search$.next(true);

    if (this.selectedUserId$.value) {
      const userFilter: UserFilter = {
        uids: [this.selectedUserId$.value],
      };

      this.userDataSource.loadFirstPage(userFilter);
    } else {
      const userFilter: UserFilter = {
        organizationId: this.selectedOrganizationId$.value,
        orderBy: this.orderByField,
        orderDirection: 'desc',
      };
      this.userDataSource.loadFirstPage(userFilter);
    }
  }

  downloadCSV() {
    this.users$
      .pipe(first(), takeUntil(this.ngDestroyed$))
      .subscribe((users) => {
        const csv = users
          .map((user) => {
            const roles = user.roles?.map((role) => getRoleName(role));
            const organization = user.organizationId;
            const createdDate = getDateAndTimeUpToSecondsFromISOString(
              user.createdDate
            );
            const lastSeen =
              user.lastSeen &&
              getDateAndTimeUpToSecondsFromISOString(user.lastSeen);

            return {
              uid: user.uid,
              name: user.name,
              email: user.email,
              roles: roles?.join(', '),
              organization,
              organizationHardLink: user.organizationHardLink,
              teachers: user.teachers?.map((t) => t.name).join(', '),
              groups: user.groups?.map((g) => g.name).join(', '),
              projectAccess: user.projectAccess,
              createdDate,
              lastSeen,
            };
          })
          .map((user) => {
            const keys = Object.keys(user);
            const values = keys.map((key) => user[key]);
            return values;
          });

        const csvData = [this.displayedColumns, ...csv];
        const dateTime = getDateAndTimeUpToSecondsFromISOString(
          new Date().toISOString()
        );

        downloadCSV(csvData, `ffRekenen Users ${dateTime}`);
      });
  }

  setProjectAccess(uid: AppUser['uid']) {
    this.dialog.open<UserProjectAccessDialogComponent>(
      UserProjectAccessDialogComponent,
      {
        data: uid,
      }
    );
  }

  setUserGroups(uid: AppUser['uid']) {
    this.dialog.open<ChooseGroupsComponent>(ChooseGroupsComponent, {
      data: uid,
    });
  }

  setUserRoles(uid: AppUser['uid']) {
    this.dialog.open<SetRolesComponent>(SetRolesComponent, {
      data: uid,
    });
  }

  displayCell(columnName: string, mappedUser: MappedUser) {
    switch (columnName) {
      case 'createdDate':
        return this.datePipe.transform(mappedUser.createdDate);
      case 'lastSeen':
        return this.datePipe.transform(mappedUser.lastSeen);
      case 'organizationHardLink':
        return (mappedUser.organizationHardLink && '✓') || null;
      case 'teachers':
        return mappedUser.teachers?.map((t) => t.name).join(', ');
      case 'groups':
        return mappedUser.groups?.map((g) => g.name).join(', ');
      case 'roles':
        return mappedUser.roles
          ?.map((r: UserRole) => getRoleName(r))
          .map((r: string) => this.titleCasePipe.transform(r))
          .join(', ');
      case 'projectAccess':
        return mappedUser.projectAccess?.join(', ');
      default:
        throw new Error(`Unknown column: ${columnName}`);
    }
  }

  impersonateUser(uid: AppUser['uid']) {
    this.store.dispatch(userActions.impersonate(uid));
  }

  deleteScoresForUser(uid: AppUser['uid']) {
    const dialogRef = this.dialog.open<DeleteUserScoresComponent>(
      DeleteUserScoresComponent,
      {
        data: {
          title: 'Scores permanent wissen',
          text: `Weet je zeker dat je alle scores van deze gebruiker wilt wissen?\n\nDeze actie kan niet ongedaan worden gemaakt.`,
          preset: DialogPreset.cancelOk,
          uid,
        },
      }
    );
  }

  copyUidToClipboard(uid: AppUser['uid']) {
    navigator.clipboard.writeText(uid);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
