import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BogusTimeService } from 'src/testing/bogus-time.service';

@Component({
  selector: 'app-time-travel-dialog',
  templateUrl: './time-travel-dialog.component.html',
  styleUrls: ['./time-travel-dialog.component.scss'],
})
export class TimeTravelDialogComponent {
  constructor(
    private timeService: BogusTimeService,
    public dialogRef: MatDialogRef<TimeTravelDialogComponent>
  ) {}

  addMinutes(minutesToAdd = 1) {
    this.timeService.addMinutes(minutesToAdd);
  }

  addSeconds() {
    this.timeService.addSeconds(1);
  }

  addHours() {
    this.timeService.addHours(1);
  }

  subtractMinutes() {
    this.timeService.addMinutes(-1);
  }

  subtractSeconds() {
    this.timeService.addSeconds(-1);
  }

  subtractHours() {
    this.timeService.addHours(-1);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
