import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { constants } from 'shared/constants';
import { mathHelper } from 'shared/helpers/math-helper';
import {
  getInteractionIdFromContextAndIndex,
  getVariantCountAtUnitPos,
} from 'src/app/helpers/unit-helpers';
import { TimeService } from 'src/app/services/time-service.interface';
import { TIME_SERVICE } from 'src/app/services/time-service.token';
import { scoreActions } from 'src/app/store/actions/score.actions';
import { showSnackbar } from 'src/app/store/actions/snackbar.actions';
import { selectUserUnitScoreState } from 'src/app/store/reducers/score.reducer';
import {
  selectUnitIdFromRouteParams,
  selectUnitPathFromRouteParams,
} from 'src/app/store/selectors/router.selectors';
import { InteractionModel } from '../../../../shared/enums/interaction-model';
import { UnitContext } from '../../../../shared/enums/unit-context';
import { utility } from '../../../../shared/helpers/utility';
import {
  AddInteractionAttemptPayload,
  InteractionAttempt,
  isFullInteractionAttempt,
} from '../../../../shared/models/user-interaction-attempt';
import { buildUserUnitScorePath } from '../../../../shared/models/user-unit-score';
import { DatabaseService } from '../../services/database.service';
import { InteractionParserService } from '../../services/interaction-parser.service';
import { StructureService } from '../../services/structure.service';
import { VariationService } from '../../services/variation.service';
import { interactionActions } from '../actions/interaction.actions';
import { notepadActions } from '../actions/notepad.actions';
import { studentActions } from '../actions/student.actions';
import { AppState } from '../reducers';
import {
  selectCurrentExamSessionInteractionAttempt,
  selectExamSessionId,
  selectIsExamSession,
} from '../reducers/exam.reducer';
import {
  getClickCountNeeded,
  selectAddInteractionAttemptPayloadWithoutTimeTaken,
  selectInteractionState,
  selectInteractionUnit,
  selectIsAllowedToSaveAnswer,
  selectIsEndOfUnit,
  selectIsThematicInteractionUnit,
  selectLastUserInteractionAttempt,
  selectStartTimestamp,
  selectUnitId,
  selectUnitScoreUid,
} from '../reducers/interaction.reducer';

@Injectable()
export class InteractionEffects {
  private readonly actions$ = inject(Actions);
  private readonly databaseService = inject(DatabaseService);
  private readonly interactionParserService = inject(InteractionParserService);
  private readonly structureService = inject(StructureService);
  private readonly store = inject(Store<AppState>);
  private readonly variationService = inject(VariationService);
  private readonly timeService = inject<TimeService>(TIME_SERVICE);

  constructor() {
    // Sync exam session state with interaction state
    this.store.select(selectIsExamSession).subscribe((isExamSession) => {
      if (isExamSession) {
        this.store.dispatch(interactionActions.setIsExamSession(true));
      } else {
        this.store.dispatch(interactionActions.setIsExamSession(false));
      }
    });
  }

  interactionIdRouteParamChangedWithUid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.routeParamsChanged),
      filter(({ uid }) => utility.isTruthy(uid)),
      switchMap(
        ({
          uid,
          projectId,
          domainId,
          chapterId,
          unitId,
          unitContext,
          interactionIndex,
        }) =>
          this.databaseService
            .getUserInteractionAttempt({
              uid,
              projectId,
              domainId,
              chapterId,
              unitId,
              unitContext,
              interactionIndex,
            })
            .pipe(
              switchMap((attempt) => {
                if (attempt) {
                  // If an attempt exists, return it immediately for further processing
                  return of(attempt);
                } else {
                  // If no attempt is present, query for an interaction ID and create a dummy attempt
                  return this.structureService.getUnit(unitId).pipe(
                    map((unit) => {
                      const interactionId = getInteractionIdFromContextAndIndex(
                        {
                          unit,
                          unitContext,
                          interactionIndex,
                          variantIndex: 0, // The user didn't work here, so might as well pick the first variant
                        }
                      );

                      const dummyAttempt: Partial<InteractionAttempt> = {
                        interactionId,
                        unitId,
                        unitContext,
                        projectId,
                        domainId,
                        chapterId,
                        uid,
                        answers: [],
                      };

                      return dummyAttempt as InteractionAttempt;
                    })
                  );
                }
              })
            )
      ),
      switchMap((attempt) => {
        const notepadLineActions =
          attempt.notepadLines?.map((line) =>
            notepadActions.add({
              id: crypto.randomUUID(),
              content: line.content,
              source: line.source,
            })
          ) ?? [];

        return [
          studentActions.setStudent({ uid: attempt.uid }),
          interactionActions.loadInteraction(attempt.interactionId),
          interactionActions.userInteractionAttemptLoaded({
            userInteractionAttempt: attempt,
          }),
          interactionActions.setIsReadonlyMode(true),
          ...notepadLineActions,
          notepadActions.isReadonlyMode({
            isReadonlyMode: true,
          }),
          notepadActions.openDialog(),
        ];
      })
    )
  );

  interactionIdRouteParamChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.routeParamsChanged),
      filter(({ interactionId }) => utility.isTruthy(interactionId)),
      map(({ interactionId }) =>
        interactionActions.loadInteraction(interactionId)
      )
    )
  );

  loadInteractionFromIndexesChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.routeParamsChanged),
      filter(
        ({ interactionId, unitId, unitContext, interactionIndex }) =>
          !interactionId &&
          !!unitId &&
          typeof unitContext === typeof UnitContext.presentation &&
          typeof interactionIndex === 'number'
      ),
      switchMap((action) =>
        this.structureService.getUnit(action.unitId).pipe(
          map((unit) => ({
            unit,
            unitContext: action.unitContext,
            interactionIndex: action.interactionIndex,
          }))
        )
      ),
      map(({ unit, unitContext, interactionIndex }) => {
        let variantCount: number | null = null;
        if (
          unit &&
          typeof unitContext === typeof UnitContext.presentation &&
          typeof interactionIndex === 'number'
        ) {
          variantCount = getVariantCountAtUnitPos({
            unit,
            unitContext,
            index: interactionIndex,
          });
        }

        return { unit, unitContext, interactionIndex, variantCount };
      }),
      map(({ unit, unitContext, interactionIndex, variantCount }) => ({
        unit,
        unitContext,
        interactionIndex,
        // TODO this should be stored somewhere in the future
        variantIndex:
          typeof variantCount === 'number'
            ? mathHelper.getRandomInt(0, variantCount - 1)
            : null,
      })),
      switchMap(({ unit, unitContext, interactionIndex, variantIndex }) => {
        const interactionId = getInteractionIdFromContextAndIndex({
          unit,
          unitContext,
          interactionIndex,
          variantIndex,
        });
        return [
          interactionActions.unitLoaded({ unit }),
          interactionActions.loadInteraction(interactionId),
        ];
      })
    )
  );

  // TODO load exam and interaction from exam section
  fetchInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.loadInteraction),
      switchMap(({ interactionId }) =>
        this.databaseService
          .getInteraction(interactionId)
          .pipe(
            map((interaction) =>
              interactionActions.interactionLoaded({ interaction })
            )
          )
      )
    )
  );

  // When the interaction is disabled and we operate within a unit context replace the disabled interaction with a placeholder.
  // This is done as a means to prevent users from being blocked by broken/disabled interactions.
  rerouteDisabledInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.interactionLoaded),
      withLatestFrom(this.store.select(selectInteractionUnit)),
      filter(([action, unit]) => action.interaction.disabled && !!unit),
      map(() =>
        interactionActions.loadInteraction(constants.placeholderInteractionId)
      )
    )
  );

  parseInteraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.interactionLoaded),
      withLatestFrom(
        this.store.select(selectInteractionUnit),
        this.store.select(selectExamSessionId),
        this.store.select(selectLastUserInteractionAttempt),
        this.store.select(selectCurrentExamSessionInteractionAttempt)
      ),
      filter(([action, unit]) => !action.interaction.disabled || !unit),
      map(
        ([
          action,
          unit,
          examSessionId,
          interactionAttempt,
          examInteractionAttempt,
        ]) => ({
          interaction: action.interaction,
          unit,
          examSessionId,
          interactionAttempt,
          examInteractionAttempt,
        })
      ),
      switchMap(
        ({
          interaction,
          unit,
          examSessionId,
          interactionAttempt,
          examInteractionAttempt,
        }) =>
          from(
            this.interactionParserService.parseInteraction(
              interaction,
              unit,
              examSessionId,
              interactionAttempt,
              examInteractionAttempt
            )
          ).pipe(
            switchMap(({ parsedInteraction, answers, lines }) => {
              const notepadLineActions = lines?.map((line) => ({
                id: crypto.randomUUID(),
                content: line.content,
                source: line.source,
              }));
              const actions = notepadLineActions.map(notepadActions.add);
              const parsedInteractionAction =
                interactionActions.interactionParsed({
                  parsedInteraction,
                  answers,
                  timestamp: this.timeService.now(),
                });
              return [...actions, parsedInteractionAction];
            })
          )
      )
    )
  );

  restoreAttemptAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.interactionParsed),
      withLatestFrom(this.store.select(selectLastUserInteractionAttempt)),
      filter(([action, lastAttempt]) => !!lastAttempt),
      map(([action, lastAttempt]) => {
        // Supported models for restore attempt answers
        if (
          [
            InteractionModel.openText,
            InteractionModel.openTextDragDrop,
            InteractionModel.openTextInImage,
            InteractionModel.openTextMultiple,
          ].includes(action.parsedInteraction.model)
        ) {
          return interactionActions.restoreAttemptState({
            attempt: lastAttempt,
          });
        }

        // Unsupported models for restore attempt answers
        return showSnackbar({
          message:
            'Dit model wordt nog niet ondersteund om antwoorden te herstellen',
          action: 'Ok',
          duration: 4000,
        });
      })
    )
  );

  unitIdRouteParamChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.store.select(selectUnitIdFromRouteParams)),
      map(([action, unitId]) => unitId),
      filter(utility.isTruthy),
      // TODO check what to do with unit state
      map((unitId) => interactionActions.loadUnit({ unitId }))
    )
  );

  fetchUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.loadUnit),
      switchMap(({ unitId }) =>
        this.structureService
          .getUnit(unitId)
          .pipe(map((unit) => interactionActions.unitLoaded({ unit })))
      )
    )
  );

  // TODO this assumes someone else needs this data under these conditions, not so clean
  requestUserUnitScore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction, studentActions.setStudent),
      withLatestFrom(
        this.store.select(selectUnitPathFromRouteParams),
        this.store.select(selectUnitScoreUid),
        this.store.select(selectUserUnitScoreState)
      ),
      filter(
        ([a, routeParams, uid, userUnitData]) =>
          // Check if all route parameters and user ID are present.
          !!routeParams.unitId &&
          !!routeParams.chapterId &&
          !!routeParams.domainId &&
          !!routeParams.projectId &&
          !!uid &&
          // Check if the user unit data is not already in the state.
          (a.type === studentActions.setStudent.type ||
            !userUnitData[buildUserUnitScorePath(routeParams)])
      ),
      map(([, routeParams, uid]) =>
        scoreActions.loadUserUnitScore({ uid, ...routeParams })
      )
    )
  );

  storeInteractionAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.handleAnswer),
      withLatestFrom(
        this.store.select(selectAddInteractionAttemptPayloadWithoutTimeTaken),
        this.store.select(selectStartTimestamp),
        this.store.select(selectIsAllowedToSaveAnswer),
        this.store.select(selectIsExamSession)
      ),
      filter(
        ([
          action,
          addAttemptPayload,
          startTimestamp,
          isAllowedToSaveAnswer,
          isExamSession,
        ]) => isAllowedToSaveAnswer && !isExamSession
      ),
      map(([action, addAttemptPayload, startTimestamp]) => ({
        ...addAttemptPayload,
        timeTaken: action.timestamp - startTimestamp,
      })),
      tap(() => {
        this.store.dispatch(
          interactionActions.setStartTime(this.timeService.now())
        );
        this.store.dispatch(interactionActions.setIsSavingAnswer(true));
      }),
      switchMap((addAttemptPayload: AddInteractionAttemptPayload) =>
        from(this.databaseService.addAttempt(addAttemptPayload)).pipe(
          catchError((error) => {
            console.error(error);
            this.store.dispatch(
              showSnackbar({
                message: 'Er was een probleem met opslaan',
                action: 'Ok',
              })
            );
            return EMPTY;
          }),
          finalize(() => {
            this.store.dispatch(interactionActions.setIsSavingAnswer(false));
          })
        )
      ),
      filter(isFullInteractionAttempt),
      map(scoreActions.mergeToUserUnitScore)
    )
  );

  handleAnswerWhenClicksComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(interactionActions.clickMediaArea),
      withLatestFrom(this.store.select(selectInteractionState)),
      filter(
        ([, state]) =>
          !!state.parsedInteraction &&
          state.parsedInteraction.model === InteractionModel.pointAndClick &&
          !!state.parsedInteraction.optionsImage &&
          getClickCountNeeded(state.parsedInteraction) ===
            state.clickedPoints.length
      ),
      map(() =>
        interactionActions.handleAnswer({ timestamp: this.timeService.now() })
      )
    )
  );

  removeThematicUnitVars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(interactionActions.handleAnswer),
        withLatestFrom(this.store.select(selectIsEndOfUnit)),
        filter(([, isEndOfUnit]) => isEndOfUnit),
        map(([action]) => action),
        withLatestFrom(this.store.select(selectIsThematicInteractionUnit)),
        filter(([, isThematicUnit]) => isThematicUnit),
        map(([action]) => action),
        withLatestFrom(this.store.select(selectUnitId)),
        tap(([, unitId]) =>
          this.variationService.removeThematicUnitVars(unitId)
        )
      ),
    { dispatch: false }
  );
}
