import { Injectable } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  QueryConstraint,
  collection,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { constants } from 'shared/constants';
import { GroupReference } from '../../../shared/models/group';
import { DbUser, UserReference } from '../../../shared/models/user';
import { SearchService } from '../types/search-service';
import {
  PaginatedResponse,
  Pagination,
  PaginationService,
} from './pagination.service';

export type UserFilter = {
  uids?: string[];
  organizationId?: DbUser['organizationId'];
  teacher?: UserReference;
  group?: GroupReference;
  orderBy?: keyof DbUser & ('name' | 'createdDate');
  orderDirection?: 'asc' | 'desc';
};

@Injectable({
  providedIn: 'root',
})
export class UserService implements SearchService<UserFilter, DbUser> {
  searchItems: (
    filter: UserFilter,
    pagination: Pagination
  ) => Observable<PaginatedResponse<DbUser>> = this.searchUsers.bind(this);

  constructor(
    private firestore: Firestore,
    private paginationService: PaginationService
  ) {}

  searchUsers(
    userFilter?: UserFilter,
    pagination?: Pagination
  ): Observable<PaginatedResponse<DbUser>> {
    const buildQuery = (ref: CollectionReference<DbUser>) => {
      const constraints: QueryConstraint[] = [];
      const orderByField = userFilter?.orderBy || 'name';

      if (userFilter?.orderDirection) {
        constraints.push(orderBy(orderByField, userFilter.orderDirection));
      } else {
        constraints.push(orderBy(orderByField));
      }

      if (userFilter?.organizationId) {
        constraints.push(
          where('organizationId', '==', userFilter.organizationId)
        );
      }

      if (userFilter?.uids) {
        constraints.push(where('uid', 'in', userFilter.uids));
      } else {
        // Only one array-contains is allowed per query
        if (userFilter?.group) {
          constraints.push(where('groups', 'array-contains', userFilter.group));
        } else if (userFilter?.teacher) {
          constraints.push(
            where('teachers', 'array-contains', userFilter.teacher)
          );
        }
      }

      return query(ref, ...constraints);
    };

    const usersRef = collection(
      this.firestore,
      constants.dbCollections.users
    ) as CollectionReference<DbUser>;
    const baseQuery = buildQuery(usersRef);

    return this.paginationService.getPage<DbUser>({
      queryName: 'users-search',
      queryFn: baseQuery,
      pagination,
    });
  }
}
