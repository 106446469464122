import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Organization } from 'shared/models/organization';
import { DatabaseService } from 'src/app/services/database.service';
import { AppState } from 'src/app/store/reducers';
import { selectUserOrganizationId } from 'src/app/store/reducers/user.reducer';
import { AppUser } from '../../../../shared/models/user';
import { selectUser } from '../../store/reducers/user.reducer';
import { SearchHit } from '../search-widget/search-widget.component';

@Component({
  selector: 'app-choose-organization',
  templateUrl: './choose-organization.component.html',
  styleUrls: ['./choose-organization.component.scss'],
})
export class ChooseOrganizationComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedOrganizationId: string = null;
  user: AppUser;
  userOrganization$: Observable<Organization>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    this.userOrganization$ = this.store.select(selectUserOrganizationId).pipe(
      switchMap((orgId) => {
        if (!orgId) {
          return of(null);
        }
        return this.databaseService.getOrganization(orgId);
      }),
      takeUntil(this.ngDestroyed$)
    );

    this.store
      .select(selectUser)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((user) => (this.user = user));
  }

  displayOrgHit(hit: SearchHit): string {
    if (hit) {
      return hit.name || hit.objectID;
    }
    return '';
  }

  onOrgSelected(hit: SearchHit) {
    this.selectedOrganizationId = hit.objectID;
  }

  onOrgQueryChanged(query: string) {
    if (!query) {
      this.selectedOrganizationId = null;
    }
  }

  save() {
    if (this.selectedOrganizationId && this.user) {
      this.loading$.next(true);
      this.databaseService
        .setOrganization(this.selectedOrganizationId)
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading$.next(false);
        });
    }
  }

  removeOrganization() {
    if (this.user) {
      this.loading$.next(true);
      this.databaseService
        .removeOrganization()
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading$.next(false);
        });
    }
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
