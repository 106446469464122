import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from 'src/app/types/search-service';
import { Pagination, PaginationDirection } from './pagination.service';

export class GenericDataSource<T, F> extends DataSource<T> {
  private ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private dataSubject = new BehaviorSubject<T[]>([]);
  private currentPageIndex = new BehaviorSubject<number>(0);

  private currentPageSize = new BehaviorSubject<number>(0);
  private filter: F;
  private paginator: MatPaginator;

  constructor(private searchService: SearchService<F, T>) {
    super();
  }

  set internalPaginator(paginator: MatPaginator) {
    if (this.paginator) {
      return;
    }
    this.paginator = paginator;
    this.paginator.page
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((v) => this.loadNextPage(v));

    this.dataSubject.pipe(takeUntil(this.ngDestroyed$)).subscribe((items) => {
      this.currentPageIndex.next(this.paginator.pageIndex);
      this.currentPageSize.next(items.length);
    });
  }

  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.currentPageIndex.complete();
    this.currentPageSize.complete();
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }

  loadFirstPage(filter: F) {
    this.filter = filter;
    this.paginator.page.emit({
      pageIndex: 0,
      pageSize: this.paginator.pageSize,
      length: this.paginator.pageSize,
    });
  }

  loadNextPage(page: PageEvent) {
    this.loadingSubject.next(true);

    const direction =
      page.pageIndex === 0
        ? PaginationDirection.first
        : page.pageIndex > page.previousPageIndex
        ? PaginationDirection.next
        : PaginationDirection.prev;

    const pagination = new Pagination(page.pageSize, direction);
    this.searchService
      .searchItems(this.filter, pagination)
      .toPromise()
      .then((items) => {
        if (direction === PaginationDirection.first) {
          this.paginator.length = items.totalCount;
        }
        this.dataSubject.next(items.data);
      })
      .catch((reason) => console.error(reason))
      .finally(() => this.loadingSubject.next(false));
  }
}
