import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { TeacherDashboardActions } from 'src/app/store/actions';
import { AppState } from 'src/app/store/reducers';
import { selectTeacherDashboardFilterState } from 'src/app/store/reducers/teacher-dashboard.reducer';

@Injectable()
export class TeacherDashboardEffects {
  private readonly actions$ = inject(Actions);
  private readonly databaseService = inject(DatabaseService);
  private readonly store = inject(Store<AppState>);

  performSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardActions.performSearch),
      withLatestFrom(this.store.select(selectTeacherDashboardFilterState)),
      switchMap(([, filters]) =>
        this.databaseService.getTeacherDashboardTableData(filters).pipe(
          map((results) =>
            TeacherDashboardActions.performSearchSuccess({ results })
          ),
          catchError(() => of(TeacherDashboardActions.performSearchFailure()))
        )
      )
    )
  );
}
