<mat-form-field appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input #searchInput matInput [formControl]="searchControl" [placeholder]="placeholder" [required]="required"
        [matAutocomplete]="auto" autocomplete="off" />
    <mat-spinner *ngIf="loading$ | async" matSuffix diameter="20"></mat-spinner>
    <button [style.visibility]="!searchControl.value || (loading$ | async) ? 'hidden' : 'visible'" mat-icon-button
        matSuffix (click)="clear()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectHit($event.option.value)"
    [displayWith]="displayWithFn">
    <mat-option *ngFor="let hit of hits" [value]="hit">
        {{displayFn(hit)}}
    </mat-option>
</mat-autocomplete>