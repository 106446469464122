import { Inject, Injectable } from '@angular/core';
import { entityValidator } from 'functions/src/helpers/entity-validator';
import { capitalize, flatten, groupBy, orderBy as lodashOrderBy } from 'lodash';
import { Observable, combineLatest, from, of } from 'rxjs';
import {
  concatMap,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { interactionReviver } from 'shared/helpers/interaction-encoder-reviver';
import { DbInteraction, Interaction } from 'shared/models/interaction';
import {
  UserChapterScore,
  buildEmptyUserChapterScore,
} from 'shared/models/user-chapter-score';
import {
  UserDomainScore,
  buildEmptyUserDomainScore,
} from 'shared/models/user-domain-score';
import {
  UserProjectScore,
  buildEmptyUserProjectScore,
} from 'shared/models/user-project-score';
import { Unit } from '../../../shared/models/unit';

import {
  CollectionReference,
  DocumentReference,
  Firestore,
  FirestoreDataConverter,
  QueryConstraint,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { constants } from 'shared/constants';
import { ArchiveScoresRequest } from 'shared/models/archive-scores-request';
import {
  TeacherDashboardFilters,
  TeacherDashboardTableDataGrouped,
} from 'shared/models/teacher-dashboard-models';
import { environment } from '../../../environments/environment';
import {
  CreateGroupPayload,
  RemoveRoleFromUserPayload,
  TeacherToUserPayload,
} from '../../../functions/src/models';
import { EntityType } from '../../../shared/enums/entity-type';
import { UserRole } from '../../../shared/enums/user-role.enum';
import {
  findChildCollection,
  findChildCollectionKey,
  findCollection,
} from '../../../shared/helpers/collection-helpers';
import {
  HoursMinutesString,
  msToHoursMinutes,
} from '../../../shared/helpers/time-helper';
import { unitReviver } from '../../../shared/helpers/unit-encoder-reviver';
import { Chapter } from '../../../shared/models/chapter';
import { Domain } from '../../../shared/models/domain';
import {
  DbGroup,
  Group,
  GroupReference,
  getGroupRef,
} from '../../../shared/models/group';
import {
  ImportOrganizationRecord,
  Organization,
} from '../../../shared/models/organization';
import { Project } from '../../../shared/models/project';
import {
  StructuralEntity,
  castToStructuralEntity,
} from '../../../shared/models/structural-entity';
import { DbUnit } from '../../../shared/models/unit';
import { AppUser, DbUser, UserReference } from '../../../shared/models/user';
import {
  AddInteractionAttemptPayload,
  AddStandaloneInteractionAttemptPayload,
  GetUserInteractionAttemptPayload,
  InteractionAttempt,
  StandaloneInteractionAttempt,
} from '../../../shared/models/user-interaction-attempt';
import {
  DraftUserUnitScore,
  UserUnitScore,
  buildDraftUserUnitScore,
} from '../../../shared/models/user-unit-score';
import { AuthService } from './auth.service';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

// TODO: Strong type cloud functions and their return types.

export type UserFilter = {
  uids?: string[];
  organizationId?: DbUser['organizationId'];
  teacher?: UserReference;
  group?: GroupReference;
  orderBy?: keyof DbUser & ('name' | 'createdDate');
  orderDirection?: 'asc' | 'desc';
};

export type GetScoreParams = {
  uid?: AppUser['uid'];
  projectId: Project['id'];
  domainId?: Domain['id'];
  chapterId?: Chapter['id'];
  unitId?: Unit['id'];
  maxTimestamp?: number;
};

export type TimePerDateArray = Array<{
  date: string; // ISO date part
  milliseconds: number;
}>;

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  // TODO: Put these converters somewhere else.
  private interactionConverter: FirestoreDataConverter<DbInteraction> = {
    toFirestore: (interaction: DbInteraction) => interaction,
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      snapshot.data() as DbInteraction,
  };

  private unitConverter: FirestoreDataConverter<DbUnit> = {
    toFirestore: (unit: DbUnit) => unit,
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      snapshot.data() as DbUnit,
  };

  private groupConverter: FirestoreDataConverter<DbGroup> = {
    toFirestore: (group: DbGroup) => group,
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      snapshot.data() as DbGroup,
  };

  constructor(
    private auth: AuthService,
    @Inject(TIME_SERVICE) private timeService: TimeService,
    private functions: Functions,
    private firestore: Firestore
  ) {}

  async importEntity(entity: any | any[]) {
    if (!Array.isArray(entity)) {
      entity = [entity];
    }

    entity.forEach((item: any) => {
      const validEntityType = entityValidator(item);
      if (!validEntityType) {
        throw new Error(
          'Data appears to be invalid or malformed. ' +
            'Method accepts a single instance or (mixed) array of Project, Domain, Chapter, Unit or Interaction'
        );
      }
    });

    const result = await httpsCallable(
      this.functions,
      'imports-entity'
    )(entity);
    return result.data;
  }

  async importSchools(schools: ImportOrganizationRecord[]) {
    if (!Array.isArray(schools)) {
      throw new Error('Expected an array of schools');
    }

    if (schools.some((school) => !school.id || !school.naam)) {
      throw new Error(
        'Data appears to be invalid or malformed. Method accepts an array of schools'
      );
    }

    const importSchoolsFn = httpsCallable(this.functions, 'imports-schools');

    // We can only write 500 records at a time.
    const batches = [];
    while (schools.length > 500) {
      const batch = schools.splice(0, 500);
      const batchResult = await importSchoolsFn(batch);
      batches.push(batchResult.data);
    }
    const finalResult = await importSchoolsFn(schools);
    batches.push(finalResult.data);

    return batches;
  }

  async importDummyData(dummyData: InteractionAttempt[]) {
    if (!Array.isArray(dummyData)) {
      throw new Error('Expected an array of dummy attempts');
    }

    if (dummyData.some((dummyRecord) => !dummyRecord.uid)) {
      throw new Error(
        'Data appears to be invalid or malformed. Method accepts an array of attempts'
      );
    }

    const result = await httpsCallable(
      this.functions,
      'user-addDummyAttempts'
    )(dummyData);
    return result.data;
  }

  async deleteDummyScores() {
    const result = await httpsCallable(
      this.functions,
      'user-deleteDummyScores'
    )(null);
    return result.data;
  }

  async deleteUserScores(uids: string[]) {
    const result = await httpsCallable(
      this.functions,
      'user-deleteScoresForUsers'
    )(uids);
    return result.data;
  }

  async deleteSpecificUserScores(request: ArchiveScoresRequest) {
    const result = await httpsCallable(
      this.functions,
      'user-archiveScoresForUser'
    )(request);
    return result.data;
  }

  getInteraction(interactionId: string): Observable<Interaction> {
    if (!interactionId) {
      throw new Error('getInteraction called without interactionId');
    }

    const interactionDocRef = doc(
      this.firestore,
      constants.dbCollections.interactions,
      interactionId
    ).withConverter(this.interactionConverter);

    return from(getDoc(interactionDocRef)).pipe(
      map((snapshot) => {
        if (!snapshot.exists()) {
          throw new Error(
            `Interaction with id ${interactionId} does not exist`
          );
        }
        return interactionReviver(snapshot.data());
      })
    );
  }

  getUnit(unitId: string): Observable<Unit> {
    if (!unitId) {
      console.error('getUnit called without unitId');
      return;
    }

    const unitDocRef = doc(
      this.firestore,
      constants.dbCollections.units,
      unitId
    ).withConverter(this.unitConverter);

    return from(getDoc(unitDocRef)).pipe(
      map((snapshot) => unitReviver(snapshot.data()))
    );
  }

  getChildrenForEntity(
    entityId: string,
    entityType: EntityType.project | EntityType.domain | EntityType.chapter
  ): Observable<StructuralEntity[] | Unit[]> {
    const col = findCollection(entityType);
    const childCollection = findChildCollection(entityType);
    const childCollectionKey = findChildCollectionKey(entityType);

    if (!childCollection || !childCollectionKey) {
      return of(null);
    }

    let childIdsUpper = [];

    const entityDocRef = doc(this.firestore, col, entityId);

    const entities$ = from(getDoc(entityDocRef)).pipe(
      map((snapshot) => snapshot.data()[childCollectionKey]),
      switchMap((childIds: StructuralEntity['children']) => {
        if (!childIds || !childIds.length) {
          return of([]);
        }

        // Firebase 'in' operator has a limit of 10.
        // We need to break up the array.
        childIdsUpper = childIds;
        const batches: Array<string[]> = [];
        const childIdsCopy = [...childIds];
        while (childIdsCopy.length > 10) {
          batches.push(childIdsCopy.splice(0, 10));
        }
        batches.push(childIdsCopy);

        const colRef = collection(
          this.firestore,
          childCollection
        ) as CollectionReference<Project | Domain | Chapter | DbUnit>;

        return combineLatest(
          batches.flatMap((childIdSegment) => {
            const qRef = query(colRef, where('id', 'in', childIdSegment));
            return from(getDocs(qRef)).pipe(
              map((snapshot) =>
                snapshot.docs
                  .map((docSnapshot) => {
                    const data = docSnapshot.data();
                    return data.type === EntityType.unit
                      ? unitReviver(data as DbUnit)
                      : castToStructuralEntity(data);
                  })
                  .sort(
                    (entity1, entity2) =>
                      childIdSegment.indexOf(entity1.id) -
                      childIdSegment.indexOf(entity2.id)
                  )
              )
            );
          })
        ).pipe(map((queryResultsArray) => flatten(queryResultsArray)));
      }),
      tap(() => {
        if (!environment.production) {
          console.count(
            `Fetch children for ${capitalize(entityType)} '${entityId}' count`
          );
        }
      }),
      tap((entityArray) => {
        if (entityArray.length !== childIdsUpper.length) {
          const missingChildren = childIdsUpper.filter(
            (childId) => !entityArray.find((entity) => entity.id === childId)
          );
          console.warn(
            `Can't find all children. Parent: ${entityId} (${capitalize(
              entityType
            )}). Missing children: ${missingChildren.join(', ')}`
          );
        }
      })
    );

    return entities$;
  }

  getEntitiesOfType(entityType: EntityType): Observable<StructuralEntity[]> {
    const col = findCollection(entityType);
    const colRef = collection(
      this.firestore,
      col
    ) as CollectionReference<StructuralEntity>;

    const constraints: QueryConstraint[] = [];

    if (col === constants.dbCollections.projects) {
      constraints.push(orderBy('disabled'));
      constraints.push(orderBy('title'));
    } else if (col === constants.dbCollections.interactions) {
      constraints.push(orderBy('id'));
    }

    const qRef = query(colRef, ...constraints);

    return from(getDocs(qRef)).pipe(
      map((snapshot) =>
        snapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data() as
            | Project
            | Domain
            | Chapter
            | DbUnit
            | DbInteraction;
          return castToStructuralEntity(data);
        })
      )
    );
  }

  async getAllInteractions(): Promise<Interaction[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.interactions
    ) as CollectionReference<DbInteraction>;

    const snapshot = await getDocs(colRef);
    return snapshot.docs.map((docSnapshot) =>
      interactionReviver(docSnapshot.data())
    );
  }

  getInteractions(
    interactionIds: Interaction['id'][]
  ): Observable<Interaction[]> {
    const batches: Interaction['id'][][] = [];
    while (interactionIds.length > 10) {
      batches.push(interactionIds.splice(0, 10));
    }
    batches.push(interactionIds);

    const colRef = collection(
      this.firestore,
      constants.dbCollections.interactions
    ) as CollectionReference<DbInteraction>;

    return combineLatest(
      batches.map((interactionIdSegment) => {
        const qRef = query(colRef, where('id', 'in', interactionIdSegment));
        return from(getDocs(qRef)).pipe(
          map((snapshot) =>
            snapshot.docs.map((docSnapshot) =>
              interactionReviver(docSnapshot.data())
            )
          )
        );
      })
    ).pipe(map((queryResultsArray) => queryResultsArray.flat()));
  }

  getObjAsStructuralEntity(
    id: string,
    entityType: EntityType
  ): Observable<StructuralEntity> {
    const col = findCollection(entityType);
    const docRef = doc(this.firestore, col, id);

    return from(getDoc(docRef)).pipe(
      map((snapshot) => {
        const data = snapshot.data() as
          | Project
          | Domain
          | Chapter
          | Unit
          | Interaction;

        if (!data) {
          console.error(`Entity '${id}' of type '${entityType}' missing in DB`);
          return { id } as StructuralEntity;
        }

        return castToStructuralEntity(data);
      })
    );
  }

  async entitySetDisabled(
    entityId: string,
    entityType: EntityType,
    disabled: boolean
  ) {
    return httpsCallable(
      this.functions,
      'imports-entitySetDisabled'
    )({
      entityId,
      entityType,
      disabled,
    });
  }

  async deleteEntity(entityId: string, entityType: EntityType) {
    return httpsCallable(
      this.functions,
      'imports-deleteEntity'
    )({
      entityId,
      entityType,
    });
  }

  getUserScoreForEntity({
    uid,
    projectId,
    domainId,
    chapterId,
    unitId,
    maxTimestamp,
  }: GetScoreParams): Observable<
    | DraftUserUnitScore
    | UserUnitScore
    | UserChapterScore
    | UserDomainScore
    | UserProjectScore
    | null
  > {
    const entityType: EntityType =
      (unitId && EntityType.unit) ||
      (chapterId && EntityType.chapter) ||
      (domainId && EntityType.domain) ||
      EntityType.project;

    const col =
      (unitId && constants.dbCollections.userUnitScores) ||
      (chapterId && constants.dbCollections.userChapterScores) ||
      (domainId && constants.dbCollections.userDomainScores) ||
      constants.dbCollections.userProjectScores;

    const colRef = collection(this.firestore, col) as CollectionReference<
      UserUnitScore | UserChapterScore | UserDomainScore | UserProjectScore
    >;

    const constraints: QueryConstraint[] = [
      where('uid', '==', uid),
      where('projectId', '==', projectId),
    ];

    if (
      entityType === EntityType.unit ||
      entityType === EntityType.chapter ||
      entityType === EntityType.domain
    ) {
      constraints.push(where('domainId', '==', domainId));
    }

    if (entityType === EntityType.unit || entityType === EntityType.chapter) {
      constraints.push(where('chapterId', '==', chapterId));
    }

    if (entityType === EntityType.unit) {
      constraints.push(where('unitId', '==', unitId));
    }

    if (maxTimestamp) {
      constraints.push(where('timestamp', '<=', maxTimestamp));
    }

    constraints.push(orderBy('timestamp', 'desc'));
    constraints.push(limit(1));

    const qRef = query(colRef, ...constraints);

    return from(getDocs(qRef)).pipe(
      map((querySnapshot) => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs[0].data();
        } else {
          // FIXME: this should return null building empty scores should not be here.

          switch (entityType) {
            case EntityType.unit:
              return buildDraftUserUnitScore({
                uid,
                unitId,
                chapterId,
                domainId,
                projectId,
              });
            case EntityType.chapter:
              return buildEmptyUserChapterScore(
                uid,
                chapterId,
                domainId,
                projectId
              );
            case EntityType.domain:
              return buildEmptyUserDomainScore(uid, domainId, projectId);
            case EntityType.project:
              return buildEmptyUserProjectScore(uid, projectId);
          }
        }
      })
    );
  }

  userScoreForProjectExists(uid: AppUser['uid'], projectId: Project['id']) {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userProjectScores
    ) as CollectionReference<UserProjectScore>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('projectId', '==', projectId),
      limit(1)
    );

    return from(getDocs(qRef)).pipe(map((snapshot) => !snapshot.empty));
  }

  // TODO can this be more readable?
  getUserChapterScoresForProject({
    uid,
    projectId,
    maxTimestamp,
  }: GetScoreParams): Observable<UserChapterScore[] | null> {
    return this.userScoreForProjectExists(uid, projectId).pipe(
      switchMap((scoreExists) => {
        if (!scoreExists) {
          return of(null);
        } else {
          return this.getObjAsStructuralEntity(
            projectId,
            EntityType.project
          ).pipe(
            map((proj: StructuralEntity) =>
              proj.children.map((domainId: Domain['id']) =>
                this.getObjAsStructuralEntity(domainId, EntityType.domain).pipe(
                  map((domain: StructuralEntity) =>
                    domain.children.map(
                      // TODO this sometimes throws, prolly because the domain is missing from the DB
                      (chapterId: Chapter['id']) =>
                        this.getUserScoreForEntity({
                          uid,
                          projectId,
                          domainId,
                          chapterId,
                          maxTimestamp,
                        }) as Observable<UserChapterScore>
                    )
                  ),
                  concatMap((chapterScoreObservables) =>
                    combineLatest(chapterScoreObservables)
                  )
                )
              )
            ),
            concatMap((domainChapterScoreArrays) =>
              combineLatest(domainChapterScoreArrays)
            ),
            map((arrayOfArrays) => arrayOfArrays.flat())
          );
        }
      })
    );
  }

  getUserUnitScoresForDomain({
    uid,
    projectId,
    domainId,
    maxTimestamp,
  }: GetScoreParams): Observable<Array<
    UserUnitScore | DraftUserUnitScore
  > | null> {
    return this.userScoreForProjectExists(uid, projectId).pipe(
      switchMap((scoreExists) => {
        if (!scoreExists) {
          return of(null);
        } else {
          return this.getObjAsStructuralEntity(
            domainId,
            EntityType.domain
          ).pipe(
            map((domain: StructuralEntity) =>
              domain.children.map((chapterId: Chapter['id']) =>
                this.getObjAsStructuralEntity(
                  chapterId,
                  EntityType.chapter
                ).pipe(
                  map((chapter: StructuralEntity) =>
                    chapter.children.map(
                      (unitId: Unit['id']) =>
                        this.getUserScoreForEntity({
                          uid,
                          projectId,
                          domainId,
                          chapterId,
                          unitId,
                          maxTimestamp,
                        }) as Observable<UserUnitScore | DraftUserUnitScore>
                    )
                  ),
                  concatMap((chapterScoreObservables) =>
                    combineLatest(chapterScoreObservables)
                  )
                )
              )
            ),
            concatMap(
              (
                userUnitScoreArrays: Observable<
                  Array<UserUnitScore | DraftUserUnitScore>
                >[]
              ) => combineLatest(userUnitScoreArrays)
            ),
            map((arrayOfArrays: Array<UserUnitScore | DraftUserUnitScore>[]) =>
              arrayOfArrays.flat()
            )
          );
        }
      })
    );
  }

  getAttemptTimePerDayForUser(
    uid: AppUser['uid'],
    daysBack?: number
  ): Observable<TimePerDateArray> {
    // Limit to last 7 days by default
    const timeLimit =
      this.timeService.now() - (daysBack ? daysBack : 7) * 24 * 60 * 60 * 1000;

    const colRef = collection(
      this.firestore,
      constants.dbCollections.userInteractionAttempts
    ) as CollectionReference<InteractionAttempt | StandaloneInteractionAttempt>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('timestamp', '>=', timeLimit)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) =>
        snapshot.docs
          .map((docSnapshot) => docSnapshot.data())
          .map((attempt) => ({
            date: new Date(attempt.timestamp).toISOString().substring(0, 10),
            milliseconds: attempt.timeTaken,
          }))
      ),
      map((dateArray) => lodashOrderBy(dateArray, 'date', 'desc')),
      map((dateArray) => {
        const groupedByDate = groupBy(dateArray, 'date');
        return Object.keys(groupedByDate).map((date) => ({
          date,
          milliseconds: groupedByDate[date].reduce(
            (acc, curr) => acc + curr.milliseconds,
            0
          ),
        }));
      }),
      map((data) =>
        data.map(({ date, milliseconds }) => ({
          date,
          milliseconds,
        }))
      )
    );
  }

  getTotalTimeForProjectForUser(
    uid: AppUser['uid'],
    projectId: Project['id']
  ): Observable<HoursMinutesString> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userProjectScores
    ) as CollectionReference<UserProjectScore>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('projectId', '==', projectId),
      orderBy('timestamp', 'desc'),
      limit(1)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) => {
        if (snapshot.empty) {
          return 0;
        } else {
          const userProjectScore = snapshot.docs[0].data();
          return userProjectScore.totalTime;
        }
      }),
      map((totalTime) => msToHoursMinutes(totalTime))
    );
  }

  getLastUserInteractionAttempt(
    uid: AppUser['uid']
  ): Observable<InteractionAttempt | null> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userInteractionAttempts
    ) as CollectionReference<InteractionAttempt | StandaloneInteractionAttempt>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      orderBy('timestamp', 'desc'),
      orderBy('projectId', 'asc'), // This is needed to filter out null values
      limit(1)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) =>
        snapshot.empty ? null : (snapshot.docs[0].data() as InteractionAttempt)
      )
    );
  }

  getUserInteractionAttempt({
    uid,
    projectId,
    domainId,
    chapterId,
    unitId,
    unitContext,
    interactionIndex,
  }: GetUserInteractionAttemptPayload): Observable<InteractionAttempt | null> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userInteractionAttempts
    ) as CollectionReference<InteractionAttempt | StandaloneInteractionAttempt>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('projectId', '==', projectId),
      where('domainId', '==', domainId),
      where('chapterId', '==', chapterId),
      where('unitId', '==', unitId),
      where('unitContext', '==', unitContext),
      where('interactionIndex', '==', interactionIndex),
      orderBy('timestamp', 'desc'),
      limit(1)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) =>
        snapshot.empty ? null : (snapshot.docs[0].data() as InteractionAttempt)
      )
    );
  }

  getUser(uid: AppUser['uid']): Observable<DbUser> {
    const docRef = doc(this.firestore, constants.dbCollections.users, uid);
    return new Observable<DbUser>((subscriber) => {
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        subscriber.next(snapshot.data() as DbUser);
      });

      // Handle cleanup
      return () => unsubscribe();
    }).pipe(takeUntil(this.auth.authState().pipe(first((user) => !user))));
  }

  getUsers(userFilter?: UserFilter): Observable<DbUser[]> {
    const colRef = collection(this.firestore, constants.dbCollections.users);
    const constraints: QueryConstraint[] = [];

    const orderByField = userFilter?.orderBy || 'name';
    if (userFilter?.orderDirection) {
      constraints.push(orderBy(orderByField, userFilter.orderDirection));
    } else {
      constraints.push(orderBy(orderByField));
    }

    if (userFilter?.organizationId) {
      constraints.push(
        where('organizationId', '==', userFilter.organizationId)
      );
    }

    if (userFilter?.uids) {
      constraints.push(where('uid', 'in', userFilter.uids));
    } else {
      // Only one array-contains is allowed per query
      if (userFilter?.group) {
        constraints.push(where('groups', 'array-contains', userFilter.group));
      } else if (userFilter?.teacher) {
        constraints.push(
          where('teachers', 'array-contains', userFilter.teacher)
        );
      }
    }

    const qRef = query(colRef, ...constraints);

    return new Observable<DbUser[]>((subscriber) => {
      const unsubscribe = onSnapshot(qRef, (snapshot) => {
        subscriber.next(
          snapshot.docs.map((docSnapshot) => docSnapshot.data() as DbUser)
        );
      });

      return () => unsubscribe();
    }).pipe(takeUntil(this.auth.authState().pipe(first((user) => !user))));
  }

  async addAttempt(
    attempt:
      | AddInteractionAttemptPayload
      | AddStandaloneInteractionAttemptPayload
  ): Promise<InteractionAttempt | StandaloneInteractionAttempt> {
    const result = await httpsCallable<
      AddInteractionAttemptPayload | AddStandaloneInteractionAttemptPayload,
      InteractionAttempt | StandaloneInteractionAttempt
    >(
      this.functions,
      'user-addAttempt'
    )(attempt);
    return result.data;
  }

  async changeName(names: {
    firstName: string;
    middleName?: string;
    lastName: string;
  }) {
    const result = await httpsCallable(
      this.functions,
      'user-changeName'
    )(names);
    return result.data;
  }

  async updateLastLogin() {
    const result = await httpsCallable(
      this.functions,
      'user-updateLastLogin'
    )(null);
    return result.data;
  }

  async deleteScores() {
    const result = await httpsCallable(
      this.functions,
      'user-deleteScores'
    )(null);
    return result.data;
  }

  getOrganizations(): Observable<Organization[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.organizations
    ) as CollectionReference<Organization>;

    const qRef = query(colRef, orderBy('name'));

    return from(getDocs(qRef)).pipe(
      map((snapshot) => snapshot.docs.map((docSnapshot) => docSnapshot.data()))
    );
  }

  getOrganization(organizationId: string): Observable<Organization | null> {
    if (!organizationId) {
      console.error('getOrganization called without ID');
      return of(null);
    }

    const docRef = doc(
      this.firestore,
      constants.dbCollections.organizations,
      organizationId
    ) as DocumentReference<Organization>;

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => docSnapshot.data() ?? null)
    );
  }

  async setOrganization(organizationId: string) {
    const result = await httpsCallable(
      this.functions,
      'user-setOrganization'
    )(organizationId);
    return result.data;
  }

  async removeOrganization() {
    const result = await httpsCallable(
      this.functions,
      'user-removeOrganization'
    )(null);
    return result.data;
  }

  async addRoleToUser(uid: DbUser['uid'], role: UserRole) {
    const result = await httpsCallable(
      this.functions,
      'user-addRoleToUser'
    )({
      uid,
      role,
    });
    return result.data;
  }

  async removeRoleFromUser(uid: DbUser['uid'], role: UserRole) {
    const result = await httpsCallable<RemoveRoleFromUserPayload>(
      this.functions,
      'user-removeRoleFromUser'
    )({
      uid,
      role,
    });
    return result.data;
  }

  async addTeacherToUser(payload: TeacherToUserPayload) {
    const result = await httpsCallable<TeacherToUserPayload>(
      this.functions,
      'user-addTeacherToUser'
    )(payload);
    return result.data;
  }

  async removeTeacherFromUser(payload: TeacherToUserPayload) {
    const result = await httpsCallable<TeacherToUserPayload>(
      this.functions,
      'user-removeTeacherFromUser'
    )(payload);
    return result.data;
  }

  async createGroup(groupPayload: CreateGroupPayload) {
    if (!groupPayload.name) {
      throw new Error('A valid group name is required');
    }

    try {
      const result = await httpsCallable<CreateGroupPayload>(
        this.functions,
        'group-createGroup'
      )(groupPayload);
      return result.data;
    } catch (err) {
      console.error('Error creating group:', err.message || err);
      throw err;
    }
  }

  async renameGroup(newGroupRef: GroupReference) {
    if (!newGroupRef.name) {
      throw new Error('A valid group name is required');
    }
    if (!newGroupRef.id) {
      throw new Error('ID is required');
    }

    try {
      const result = await httpsCallable<GroupReference>(
        this.functions,
        'group-renameGroup'
      )(newGroupRef);
      return result.data;
    } catch (err) {
      console.error('Error renaming group:', err.message || err);
      throw err;
    }
  }

  async deleteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    try {
      const result = await httpsCallable<Group['id'], void>(
        this.functions,
        'group-deleteGroup'
      )(groupId);
      return result.data;
    } catch (err) {
      console.error('Error deleting group:', err.message || err);
      throw err;
    }
  }

  getGroupMemberCount(group: Group): Observable<number> {
    const colRef = collection(this.firestore, constants.dbCollections.users);
    const qRef = query(
      colRef,
      where('organizationId', '==', group.organizationId),
      where('groups', 'array-contains', getGroupRef(group))
    );

    return from(getDocs(qRef)).pipe(map((snapshot) => snapshot.size));
  }

  async addGroupToUser(groupId: Group['id'], targetUid?: DbUser['uid']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    if (targetUid) {
      const result = await httpsCallable(
        this.functions,
        'user-addGroupToUser'
      )({ groupId, uid: targetUid });
      return result.data;
    } else {
      const result = await httpsCallable(
        this.functions,
        'user-addGroup'
      )(groupId);
      return result.data;
    }
  }

  async removeGroupFromUser(groupId: Group['id'], targetUid?: DbUser['uid']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    if (targetUid) {
      const result = await httpsCallable(
        this.functions,
        'user-removeGroupFromUser'
      )({ groupId, uid: targetUid });
      return result.data;
    } else {
      const result = await httpsCallable(
        this.functions,
        'user-removeGroup'
      )(groupId);
      return result.data;
    }
  }

  async addFavoriteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    const result = await httpsCallable<Group['id'], void>(
      this.functions,
      'user-addFavoriteGroup'
    )(groupId);
    return result.data;
  }

  async removeFavoriteGroup(groupId: Group['id']) {
    if (!groupId) {
      throw new Error('ID is required');
    }

    const result = await httpsCallable<Group['id'], void>(
      this.functions,
      'user-removeFavoriteGroup'
    )(groupId);
    return result.data;
  }

  async addProjectAccessToUser(projectId: Project['id'], uid: DbUser['uid']) {
    if (!projectId) {
      throw new Error('ID is required');
    }

    const result = await httpsCallable(
      this.functions,
      'user-addProjectAccessToUser'
    )({ projectId, uid });
    return result.data;
  }

  async removeProjectAccessFromUser(
    projectId: Project['id'],
    uid: DbUser['uid']
  ) {
    if (!projectId) {
      throw new Error('ID is required');
    }

    const result = await httpsCallable(
      this.functions,
      'user-removeProjectAccessFromUser'
    )({ projectId, uid });
    return result.data;
  }

  getFilteredProjectsByAccess(projectIds?: string[]): Observable<Project[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.projects
    ) as CollectionReference<Project>;

    const constraints: QueryConstraint[] = [];

    // Either get all public projects or only those assigned to the user.
    if (projectIds?.length) {
      constraints.push(where('id', 'in', projectIds));
    } else {
      constraints.push(where('public', '==', true));
    }

    constraints.push(orderBy('disabled'));
    constraints.push(orderBy('title'));

    const qRef = query(colRef, ...constraints);

    return from(getDocs(qRef)).pipe(
      map((snapshot) => snapshot.docs.map((docSnapshot) => docSnapshot.data()))
    );
  }

  getProjectsForUser(user: AppUser): Observable<Project[]> {
    if (!user) {
      return of([]).pipe(first());
    }

    return this.getFilteredProjectsByAccess(user.projectAccess).pipe(
      takeUntil(this.auth.authState().pipe(filter((u) => !u)))
    );
  }

  getProjectsForStudent(uid: string): Observable<Project[]> {
    return this.getUser(uid).pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        return this.getFilteredProjectsByAccess(user.projectAccess);
      })
    );
  }

  getProjectsWithScoresForUser(uid: string): Observable<Project[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userProjectScores
    ) as CollectionReference<UserProjectScore>;

    const qRef = query(colRef, where('uid', '==', uid));

    return from(getDocs(qRef)).pipe(
      map((snapshot) => [
        ...new Set(
          snapshot.docs.map((docSnapshot) => docSnapshot.data().projectId)
        ),
      ]),
      switchMap((projectIds) => this.getProjects(projectIds))
    );
  }

  getProjects(projectIds: string[]): Observable<Project[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.projects
    ) as CollectionReference<Project>;

    const qRef = query(colRef, where('id', 'in', projectIds));

    return from(getDocs(qRef)).pipe(
      map((snapshot) => snapshot.docs.map((docSnapshot) => docSnapshot.data())),
      takeUntil(this.auth.authState().pipe(first((user) => !user)))
    );
  }

  getDomainsWithScoresForUser(
    uid: string,
    projectId: string
  ): Observable<string[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userDomainScores
    ) as CollectionReference<UserDomainScore>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('projectId', '==', projectId)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) => [
        ...new Set(
          snapshot.docs.map((docSnapshot) => docSnapshot.data().domainId)
        ),
      ])
    );
  }

  getChaptersWithScoresForUser(
    uid: string,
    projectId: string,
    domainId: string
  ): Observable<string[]> {
    const colRef = collection(
      this.firestore,
      constants.dbCollections.userChapterScores
    ) as CollectionReference<UserChapterScore>;

    const qRef = query(
      colRef,
      where('uid', '==', uid),
      where('projectId', '==', projectId),
      where('domainId', '==', domainId)
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) => [
        ...new Set(
          snapshot.docs.map((docSnapshot) => docSnapshot.data().chapterId)
        ),
      ])
    );
  }

  getGroupsForOrg(organizationId: Organization['id']): Observable<Group[]> {
    if (!organizationId) {
      console.error('getGroups called without ID');
      return;
    }

    const colRef = collection(
      this.firestore,
      constants.dbCollections.groups
    ).withConverter(this.groupConverter);
    const qRef = query(
      colRef,
      where('organizationId', '==', organizationId),
      orderBy('name')
    );

    return from(getDocs(qRef)).pipe(
      map((snapshot) =>
        snapshot.docs.map((docSnapshot) => ({
          ...docSnapshot.data(),
          id: docSnapshot.id,
        }))
      )
    );
  }

  async getAlgoliaUserKey(): Promise<string> {
    const result = await httpsCallable<null, string>(
      this.functions,
      'algolia-createUserSearchKey'
    )(null);
    return result.data;
  }

  async getAlgoliaOrgKey(): Promise<string> {
    const result = await httpsCallable<null, string>(
      this.functions,
      'algolia-createOrgSearchKey'
    )(null);
    return result.data;
  }

  // Returns a token that can be used to login as the target user.
  async impersonate(targetUid: AppUser['uid']): Promise<string> {
    const result = await httpsCallable<string, string>(
      this.functions,
      'user-impersonate'
    )(targetUid);
    return result.data;
  }

  getTeacherDashboardTableData(
    filters: TeacherDashboardFilters
  ): Observable<TeacherDashboardTableDataGrouped> {
    return from(
      httpsCallable<TeacherDashboardFilters, TeacherDashboardTableDataGrouped>(
        this.functions,
        'teacher-getTeacherDashboardData'
      )(filters)
    ).pipe(map((result) => result.data));
  }
}
