<h1 mat-dialog-title>Kies je school</h1>

<mat-dialog-content>
    <h4 *ngIf="(userOrganization$ | async)">Huidige school: {{ (userOrganization$ | async)?.name }}</h4>
    <br />
    <app-search-widget [label]="'School'" [type]="'organizations'" [displayFn]="displayOrgHit"
        (selected)="onOrgSelected($event)" (queryChanged)="onOrgQueryChanged($event)">
    </app-search-widget>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Sluiten</button>
    <button mat-raised-button [disabled]="!user || (loading$ | async) || !(userOrganization$ | async | truthy)"
        (click)="removeOrganization()">Verwijder</button>
    <button mat-raised-button
        [disabled]="!user || !selectedOrganizationId || (loading$ | async) || (userOrganization$ | async)?.id === selectedOrganizationId"
        (click)="save()">Opslaan</button>
</mat-dialog-actions>