import { Inject, Injectable } from '@angular/core';
import {
  Firestore,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from '@angular/fire/functions';
import {
  CreateExamInstancePayload,
  SaveExamAnswerPayload,
} from 'functions/src/models';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { constants } from 'shared/constants';
import { Exam } from 'shared/models/exam';
import {
  ExamSession,
  ExamSessionFinalized,
  ExamSessionNotSubmitted,
  isFinalizedExamSession,
} from 'shared/models/exam-session';
import {
  ExamInstance,
  ExamInstanceResults,
} from '../../../shared/models/exam-instance';
import {
  ExamResultsOverview,
  ExamSessionResult,
} from '../../../shared/models/exam-result';
import { ExamResultFilter } from '../../../shared/models/exam-result-filter';
import { Interaction } from '../../../shared/models/interaction';
import { EducationTrack } from '../../../shared/models/project';
import { AppUser } from '../../../shared/models/user';
import { StandaloneInteractionAttempt } from '../../../shared/models/user-interaction-attempt';
import { FirestoreService } from './firestore.service';
import { TimeService } from './time-service.interface';
import { TIME_SERVICE } from './time-service.token';

export interface ExamAdjacentIndexes {
  previous: ExamSessionIndex;
  next: ExamSessionIndex;
}

export type ExamSessionIndex = {
  sectionIndex: number;
  interactionIndex: number;
} | null;

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  // TODO: move converters to centralized place
  private examConverter: FirestoreDataConverter<Exam> = {
    toFirestore: (exam: Exam) => exam,
    fromFirestore: (snapshot: QueryDocumentSnapshot) => snapshot.data() as Exam,
  };

  private examSessionConverter: FirestoreDataConverter<ExamSession> = {
    toFirestore: (session: ExamSession) => session,
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      ({
        ...snapshot.data(),
        id: snapshot.id,
      } as ExamSession),
  };

  constructor(
    private functions: Functions,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
    @Inject(TIME_SERVICE) private timeService: TimeService
  ) {}

  saveExamAnswer(
    saveExamAnswerPayload: SaveExamAnswerPayload
  ): Promise<HttpsCallableResult<void>> {
    return httpsCallable<SaveExamAnswerPayload, void>(
      this.functions,
      'exams-saveExamAnswer'
    )(saveExamAnswerPayload);
  }

  // TODO not used?
  async getExamSessionAnswerByIndex({
    examSessionId,
    examSectionIndex,
    examInteractionIndex,
  }: {
    examSessionId: ExamSession['id'];
    examSectionIndex: number;
    examInteractionIndex: number;
  }): Promise<StandaloneInteractionAttempt['answers'] | undefined> {
    const result = await httpsCallable<string, ExamSession>(
      this.functions,
      'exams-getExamSession'
    )(examSessionId);
    const examSession = result.data;
    const examSection = examSession?.sections[examSectionIndex];
    const examInteraction = examSection?.interactions[examInteractionIndex];
    return examInteraction?.answers;
  }

  async startExamSession(
    examSessionId: ExamSessionNotSubmitted['id']
  ): Promise<ExamSessionNotSubmitted> {
    const result = await httpsCallable<string, ExamSessionNotSubmitted>(
      this.functions,
      'exams-startExamSession'
    )(examSessionId);
    return result.data;
  }

  async submitExamSession(
    examSessionId: ExamSessionNotSubmitted['id']
  ): Promise<ExamSessionResult> {
    const result = await httpsCallable<string, ExamSessionResult>(
      this.functions,
      'exams-submitExamSession'
    )(examSessionId);
    return result.data;
  }

  async createExamSession(
    examInstanceId: ExamInstance['id']
  ): Promise<ExamSessionNotSubmitted['id']> {
    const result = await httpsCallable<string, ExamSessionNotSubmitted['id']>(
      this.functions,
      'exams-createExamSession'
    )(examInstanceId);
    return result.data;
  }

  getExamInstanceById(id: ExamInstance['id']): Observable<ExamInstance> {
    return from(
      httpsCallable<string, ExamInstance>(
        this.functions,
        'exams-getExamInstanceById'
      )(id)
    ).pipe(map((result) => result.data));
  }

  async getExamInstanceResultsById(
    id: ExamInstance['id']
  ): Promise<ExamResultsOverview> {
    const result = await httpsCallable<string, ExamResultsOverview>(
      this.functions,
      'exams-getExamInstanceResultsById'
    )(id);
    return result.data;
  }

  async getExamInstanceByPassphrase(passphrase: string): Promise<ExamInstance> {
    const result = await httpsCallable<string, ExamInstance>(
      this.functions,
      'exams-getExamInstanceByPassphrase'
    )(passphrase);
    return result.data;
  }

  getExamSession(sessionId: ExamSession['id']): Observable<ExamSession> {
    if (!sessionId) {
      throw new Error('sessionId is required');
    }
    return from(
      httpsCallable<string, ExamSession>(
        this.functions,
        'exams-getExamSession'
      )(sessionId)
    ).pipe(map((result) => result.data));
  }

  async createExamInstance(payload: CreateExamInstancePayload): Promise<void> {
    const result = await httpsCallable<CreateExamInstancePayload, void>(
      this.functions,
      'exams-createExamInstance'
    )(payload);
    return result.data;
  }

  getExamSessionsForUser(uid: AppUser['uid']): Observable<ExamSession[]> {
    if (!uid) {
      throw new Error('UID is required');
    }

    const examSessionsRef = this.firestoreService
      .collection(this.firestore, constants.dbCollections.examSessions)
      .withConverter(this.examSessionConverter);
    const q = this.firestoreService.query(
      examSessionsRef,
      this.firestoreService.where('uid', '==', uid)
    );

    return from(this.firestoreService.getDocs(q)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((docSnap) => docSnap.data())
      ),
      map((examSessions) =>
        examSessions.sort((a, b) => {
          const aIsFinalized = this.isFinalizedExamSessionWrapped(a);
          const bIsFinalized = this.isFinalizedExamSessionWrapped(b);

          if (!a.startedAt && !b.startedAt) {
            return 0;
          }

          if (!a.startedAt) {
            return 1;
          }

          if (!b.startedAt) {
            return -1;
          }

          if (!aIsFinalized && !bIsFinalized) {
            return b.startedAt - a.startedAt;
          }

          if (!aIsFinalized) {
            return -1;
          }

          if (!bIsFinalized) {
            return 1;
          }

          return b.finishedAt - a.finishedAt;
        })
      )
    );
  }

  getExam(examId: Exam['id']): Observable<Exam> {
    const examRef = this.firestoreService
      .doc(this.firestore, constants.dbCollections.exams, examId)
      .withConverter(this.examConverter);
    return from(this.firestoreService.getDoc(examRef)).pipe(
      map((docSnapshot) => docSnapshot.data())
    );
  }

  getExams(educationTrack?: EducationTrack): Observable<Exam[]> {
    const examsRef = this.firestoreService
      .collection(this.firestore, constants.dbCollections.exams)
      .withConverter(this.examConverter);
    const q = this.firestoreService.query(
      examsRef,
      this.firestoreService.orderBy('name')
    );
    return from(this.firestoreService.getDocs(q)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((docSnap) => docSnap.data())
      )
    );
  }

  async getExamInstances(
    filter: ExamResultFilter
  ): Promise<ExamInstanceResults[]> {
    const result = await httpsCallable<ExamResultFilter, ExamInstanceResults[]>(
      this.functions,
      'exams-getExamInstances'
    )(filter);
    return result.data;
  }

  storeExamInteractionShuffledVars(
    examSessionId: ExamSession['id'],
    examSectionIndex: number,
    examInteractionIndex: number,
    interactionId: Interaction['id'],
    varsShuffledIndexes: Interaction['varsShuffledIndexes']
  ): Observable<void> {
    return from(
      httpsCallable<any, void>(
        this.functions,
        'exams-storeExamInteractionShuffledVars'
      )({
        address: {
          examSessionId,
          examSectionIndex,
          examInteractionIndex,
          interactionId,
        },
        vars: varsShuffledIndexes,
      })
    ).pipe(map((result) => result.data));
  }

  isFinalizedExamSessionWrapped(
    examSession: ExamSession
  ): examSession is ExamSessionFinalized {
    return isFinalizedExamSession(
      examSession as ExamSessionFinalized,
      this.timeService.now()
    );
  }

  getExamSessionResult(
    examSessionId: ExamSession['id']
  ): Observable<ExamSessionResult> {
    return from(
      httpsCallable<string, ExamSessionResult>(
        this.functions,
        'exams-getExamSessionResult'
      )(examSessionId)
    ).pipe(map((result) => result.data));
  }
}
