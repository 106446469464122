import { Injectable } from '@angular/core';
import { TimeService } from './time-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalTimeService implements TimeService {
  getPastDates(daysBack: number): Date[] {
    const dates: Date[] = [];

    for (let i = 0; i < daysBack; i++) {
      dates.push(new Date(this.now() - i * 24 * 60 * 60 * 1000));
    }

    return dates.reverse();
  }

  now(): number {
    return Date.now();
  }
}
