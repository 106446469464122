import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DatabaseService } from 'src/app/services/database.service';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { DbUser, UserReference } from '../../../../shared/models/user';
import { AppState } from '../../store/reducers';
import {
  selectUserOrganizationId,
  selectUserTeachers,
} from '../../store/reducers/user.reducer';
import { SearchHit } from '../search-widget/search-widget.component';

@Component({
  selector: 'app-choose-teachers',
  templateUrl: './choose-teachers.component.html',
  styleUrls: ['./choose-teachers.component.scss'],
})
export class ChooseTeachersComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  userTeachers$: Observable<
    (UserReference & {
      beingRemoved: boolean;
    })[]
  >;
  selectedTeacherUid$ = new BehaviorSubject<UserReference['uid']>(null);
  teachersBeingAdded$ = new BehaviorSubject<DbUser['uid'][]>([]);
  teachersBeingRemoved$ = new BehaviorSubject<DbUser['uid'][]>([]);
  canAddTeacher$: Observable<boolean>;
  organizationId$: Observable<string>;
  searchFilters$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private databaseService: DatabaseService
  ) {}

  ngOnInit() {
    this.userTeachers$ = combineLatest([
      this.store.select(selectUserTeachers),
      this.teachersBeingRemoved$,
    ]).pipe(
      map(([teachers, teachersBeingRemoved]) =>
        teachers.map((teacher) => ({
          ...teacher,
          beingRemoved: teachersBeingRemoved.includes(teacher.uid),
        }))
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.canAddTeacher$ = combineLatest([
      this.selectedTeacherUid$,
      this.teachersBeingAdded$,
      this.store.select(selectUserTeachers),
    ]).pipe(
      map(
        ([teacherUid, teachersBeingAdded, userTeachers]) =>
          teacherUid &&
          !teachersBeingAdded.includes(teacherUid) &&
          !userTeachers.map(({ uid }) => uid).includes(teacherUid)
      ),
      takeUntil(this.ngDestroyed$)
    );

    this.organizationId$ = this.store.select(selectUserOrganizationId);
    this.searchFilters$ = this.organizationId$.pipe(
      map(
        (organizationId) =>
          `roles:${UserRole.teacher}${
            organizationId ? ` AND organizationId:${organizationId}` : ''
          }`
      )
    );
  }

  displayTeacherHit(hit: SearchHit): string {
    if (hit) {
      return hit.name || hit.objectID;
    }
    return '';
  }

  onTeacherSelected(hit: SearchHit) {
    this.selectedTeacherUid$.next(hit.objectID);
  }

  onTeacherQueryChanged(query: string) {
    if (!query) {
      this.selectedTeacherUid$.next(null);
    }
  }

  addTeacher() {
    const teacherUid = this.selectedTeacherUid$.getValue();

    this.teachersBeingAdded$.next([
      ...this.teachersBeingAdded$.getValue(),
      teacherUid,
    ]);

    this.databaseService
      .addTeacherToUser({ teacherUid })
      .catch((e) => console.error(e))
      .finally(() => {
        this.teachersBeingAdded$.next(
          this.teachersBeingAdded$
            .getValue()
            .filter((uid) => uid !== teacherUid)
        );
      });
  }

  removeTeacher(teacherUid: UserReference['uid']) {
    this.teachersBeingRemoved$.next([
      ...this.teachersBeingRemoved$.getValue(),
      teacherUid,
    ]);
    this.databaseService
      .removeTeacherFromUser({ teacherUid })
      .catch((e) => console.error(e))
      .finally(() => {
        this.teachersBeingRemoved$.next(
          this.teachersBeingRemoved$
            .getValue()
            .filter((uid) => uid !== teacherUid)
        );
      });
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
