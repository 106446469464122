import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import algoliasearch from 'algoliasearch/lite';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { constants } from '../../../shared/constants';
import { utility } from '../../../shared/helpers/utility';
import { AppState } from '../store/reducers';
import {
  selectAlgoliaOrgKey,
  selectAlgoliaUserKey,
} from '../store/reducers/user.reducer';

type SearchType = keyof typeof constants.algoliaIndices;

interface SearchInstance {
  index: ReturnType<ReturnType<typeof algoliasearch>['initIndex']>;
  loading: boolean;
}

const searchConfig: Record<
  SearchType,
  { index: string; selector: (state: AppState) => string | null }
> = {
  organizations: {
    index: constants.algoliaIndices.organizations,
    selector: selectAlgoliaOrgKey,
  },
  users: {
    index: constants.algoliaIndices.users,
    selector: selectAlgoliaUserKey,
  },
};

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService implements OnDestroy {
  private searchInstances = new Map<
    SearchType,
    BehaviorSubject<SearchInstance>
  >([
    [
      'organizations',
      new BehaviorSubject<SearchInstance>({ index: null, loading: false }),
    ],
    [
      'users',
      new BehaviorSubject<SearchInstance>({ index: null, loading: false }),
    ],
  ]);
  private destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>) {
    Object.entries(searchConfig).forEach(([type, config]) => {
      this.initSearchInstance(type as SearchType, config);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.searchInstances.forEach((subject) => {
      subject.complete();
    });
  }

  public getSearch(
    type: SearchType
  ): Observable<ReturnType<
    ReturnType<typeof algoliasearch>['initIndex']
  > | null> {
    return new Observable((subscriber) => {
      const subject = this.searchInstances.get(type);
      if (!subject) {
        subscriber.error(new Error(`Invalid search type: ${type}`));
        return;
      }
      return subject
        .pipe(map((instance) => instance.index))
        .subscribe(subscriber);
    });
  }

  public isLoading(type: SearchType): Observable<boolean> {
    return new Observable((subscriber) => {
      const subject = this.searchInstances.get(type);
      if (!subject) {
        subscriber.error(new Error(`Invalid search type: ${type}`));
        return;
      }
      return subject
        .pipe(map((instance) => instance.loading))
        .subscribe(subscriber);
    });
  }

  public async search(type: SearchType, query: string): Promise<any[]> {
    const instance = this.searchInstances.get(type)?.value;
    if (!instance?.index) {
      return [];
    }

    this.updateLoading(type, true);
    try {
      const { hits } = await instance.index.search(query);
      return hits;
    } catch (error) {
      console.error(`Error searching ${type}:`, error);
      return [];
    } finally {
      this.updateLoading(type, false);
    }
  }

  private initSearchInstance(
    type: SearchType,
    config: { index: string; selector: (state: AppState) => string | null }
  ): void {
    this.store
      .select(config.selector)
      .pipe(filter(utility.isTruthy), takeUntil(this.destroy$))
      .subscribe((apiKey) => {
        const client = algoliasearch(environment.algoliaAppId, apiKey);
        const index = client.initIndex(config.index);

        this.searchInstances.get(type)?.next({
          index,
          loading: false,
        });
      });
  }

  private updateLoading(type: SearchType, loading: boolean): void {
    const subject = this.searchInstances.get(type);
    if (subject) {
      subject.next({ ...subject.value, loading });
    }
  }
}
