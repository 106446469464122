import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityType } from 'shared/enums/entity-type';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { DatabaseService } from 'src/app/services/database.service';
import { Project } from '../../../../shared/models/project';
import { StructuralEntity } from '../../../../shared/models/structural-entity';
import { AppUser, DbUser } from '../../../../shared/models/user';
import { StructureService } from '../../services/structure.service';

@Component({
  selector: 'app-user-project-access-dialog',
  templateUrl: './user-project-access-dialog.component.html',
  styleUrls: ['./user-project-access-dialog.component.scss'],
})
export class UserProjectAccessDialogComponent implements OnInit, OnDestroy {
  ngDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  displayedColumns = ['title', 'actions'];
  projects$: Observable<StructuralEntity[]>;
  user: DbUser;

  constructor(
    private databaseService: DatabaseService,
    private structureService: StructureService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public uid: AppUser['uid']
  ) {}

  ngOnInit() {
    this.projects$ = this.structureService.getEntitiesOfType(
      EntityType.project
    );

    this.databaseService
      .getUser(this.uid)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((user) => {
        if (!user) {
          return alert('User not found');
        }

        this.user = user;
      });
  }

  userHasProjectAccess(projectId: Project['id']) {
    return this.user?.projectAccess?.some((projId) => projId === projectId);
  }

  addAccess(projectId: Project['id']) {
    this.databaseService.addProjectAccessToUser(projectId, this.uid);
  }

  removeAccess(projectId: Project['id']) {
    this.databaseService.removeProjectAccessFromUser(projectId, this.uid);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(true);
    this.ngDestroyed$.complete();
  }
}
