<div class="bg-image"></div>

<div class="grid-container" [ngClass]="{'test-bg': isTest$ | async}">
    <mat-card>
        <div class="context-location">{{ contextLocation$ | async }}</div>
        <div class="interaction-container">
            <div #mediaContainer class="media-container">
                <img #interactionImage class="main-image" alt="interaction image" *ngIf="(imageSrc$ | async)"
                    [ngClass]="{'no-scaling': noImageScaling$ | async}" (click)="clickImage($event)"
                    [src]="(imageSrc$ | async) | safeResource" [ngStyle]="{ 
                    paddingLeft: ((parsedInteraction$ | async)?.dragElementsPosition === 'left' && (parsedInteraction$ | async)?.draggableWidth || 0) + 'px'
                }" />

                <div class="video-container" [hidden]="(showVideo$ | async) === false">
                    <!-- 
                        FIXME: height is hardcoded at 400 even for smaller videos,
                        it causes problems with the presentation click coordinates when shown on a different size.                    
                    -->
                    <video #interactionVideo width="640" height="400" (click)="clickVideo($event)">
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <mat-icon class="play-icon" *ngIf="(showVideo$ | async) && interactionVideo.paused">
                        play_circle_outline
                    </mat-icon>
                </div>

                <div *ngIf="(presentationIndicationArea$ | async) as indicationArea" [ngStyle]="{
                    'width': indicationArea.width + 'px', 
                    'height': indicationArea.height + 'px', 
                    'top': indicationArea.topLeft.y + videoPlayer.nativeElement.offsetTop + 'px', 
                    'left': indicationArea.topLeft.x + videoPlayer.nativeElement.offsetLeft + 'px'
                }" class="area"></div>

                <div class="hotspot-sort" *ngIf="(parsedInteraction$ | async)?.optionsHotspot">
                    <div class="hotspot-option"
                        *ngFor="let draggable of (parsedInteraction$ | async).optionsHotspot; let i = index" cdkDrag
                        [cdkDragData]="draggable" [cdkDragDisabled]="draggable.disabled"
                        [cdkDragFreeDragPosition]="draggable.cdkDragFreeDragPosition"
                        (cdkDragEnded)="hotspotDragEnd($event)" cdkDragBoundary=".media-container"
                        [class]="{ correct: draggable.correct, border: (parsedInteraction$ | async).optionBorder }">
                        <div class="hotspot-option-content"
                            [innerHTML]="draggable.option + draggable?.picture | safeHtml"></div>
                        <app-point-spot [num]="i + 1"
                            [hidden]="draggable.correct !== true && draggable.correct !== false"
                            [correct]="draggable.correct"></app-point-spot>
                    </div>
                </div>

                <div class="sort-order" cdkDropList *ngIf="(parsedInteraction$ | async)?.optionsSortOrder"
                    [cdkDropListData]="(parsedInteraction$ | async)?.optionsSortOrder"
                    (cdkDropListDropped)="sortOrderDrop($event)">
                    <div class="sort-order-draggable"
                        *ngFor="let item of (parsedInteraction$ | async)?.optionsSortOrder" cdkDrag
                        [cdkDragData]="item">
                        <div *ngIf="item?.picture" [innerHTML]="item.picture | safeHtml"></div>
                        <div *ngIf="item?.option" [innerHTML]="item.option | safeHtml"></div>
                    </div>
                </div>

                <div class="open-text-drag" *ngIf="(parsedInteraction$ | async)?.draggable"
                    [ngStyle]="{ float: (parsedInteraction$ | async)?.dragElementsPosition === 'left' && 'left' || 'initial' }">
                    <div class="open-text-draggable"
                        *ngFor="let counter of (openTextDraggables$ | async | arrayFiller).reverse()" cdkDrag
                        [cdkDragData]="counter" (cdkDragEnded)="openTextDragEnd($event)"
                        (cdkDragStarted)="openTextDragStart($event)" cdkDragBoundary=".media-container">
                        <img alt="open text draggable" [src]="(parsedInteraction$ | async)?.draggable | safeResource" />
                        <div class="point-spot">{{ counter }}</div>
                    </div>
                </div>

                <div *ngIf="(parsedInteraction$ | async)?.draggable" class="open-text-drag-spacer" [ngStyle]="{ 
                    height: ((parsedInteraction$ | async)?.dragElementsPosition !== 'left' && (parsedInteraction$ | async)?.draggableHeight || 0) + 'px' 
                }"></div>

                <div class="point-clicks-container">
                    <app-point-spot *ngFor="let clickedPoint of clickedPoints$ | async; let i = index" [num]="i + 1"
                        [correct]="clickedPoint.isCorrect" [ngStyle]="{ 
                            top: clickedPoint.point.y + 'px', 
                            left: clickedPoint.point.x + 'px' 
                        }"></app-point-spot>
                </div>
            </div>

            <div class="question-area" *ngIf="(question$ | async) as question" [innerHTML]="question | safeHtml">
            </div>

            <div #answerArea class="answer-area">
                <div class="gap" *ngIf="(parsedInteraction$ | async)?.gap"
                    [innerHTML]="(parsedInteraction$ | async)?.gap | safeHtml"></div>

                <mat-radio-group tabindex="-1" *ngIf="radioOptions$ | async as radioOptions" (change)="markRadio(null)"
                    [(ngModel)]="radioSelected">
                    <mat-radio-button [disabled]="(locked$ | async)" tabindex="-1" *ngFor="let option of radioOptions"
                        [value]="option">
                        {{ option }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="feedback-area" *ngIf="feedback$ | async as feedback" [innerHTML]="feedback | safeHtml">
            </div>

            <div class="button-area">
                <!-- Exam nav -->
                <button *ngIf="(showPrevExamInteractionButton$ | async)" class="skip-focus" mat-raised-button
                    (click)="routeExamPrevious()">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button *ngIf="(showNextExamInteractionButton$ | async)" mat-raised-button (click)="routeExamNext()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>

                <!-- Audio controls -->
                <mat-card class="audio-controls" *ngIf="soundEnabled$ | async">
                    <!-- Audio play/pause button -->
                    <button mat-icon-button (click)="audio.togglePlay()" class="skip-focus">
                        <mat-icon *ngIf="(audio.playing$ | async) !== true">play_arrow</mat-icon>
                        <mat-icon *ngIf="audio.playing$ | async">pause</mat-icon>
                    </button>

                    <!-- Volume slider -->
                    <mat-slider class="volume-slider skip-focus" [value]="audio.volume$ | async"
                        (input)="audio.setVolume($event.value)" min="0" max="1" step="0.01">
                    </mat-slider>

                    <!-- Mute/unmute button -->
                    <button mat-icon-button (click)="audio.toggleMute()" class="skip-focus">
                        <mat-icon *ngIf="(audio.muted$ | async) !== true">volume_up</mat-icon>
                        <mat-icon *ngIf="audio.muted$ | async">volume_off</mat-icon>
                    </button>
                </mat-card>

                <!-- Video play/pause button -->
                <button *ngIf="showVideo$ | async" mat-raised-button class="skip-focus" (click)="clickVideoPlayPause()">
                    <mat-icon *ngIf="interactionVideo.paused">play_arrow</mat-icon>
                    <mat-icon *ngIf="!interactionVideo.paused">pause</mat-icon>
                </button>

                <!-- Calculator button -->
                <button mat-raised-button class="skip-focus" (click)="clickCalculator()">
                    <mat-icon>calculate</mat-icon>
                </button>

                <!-- Stop button -->
                <button mat-raised-button *ngIf="showStopButton$ | async" class="skip-focus"
                    (click)="clickStop()">Stop</button>

                <!-- Skip button -->
                <button *ngIf="showSkipButton$ | async" [disabled]="disableSkipButton$ | async" mat-raised-button
                    class="skip-focus" (click)="routeNext()">
                    Sla over
                </button>

                <!-- Fast forward button -->
                <button *ngIf="showFastForwardButton$ | async" mat-raised-button (click)="routeNext(true)">Toets <i
                        class="material-icons">
                        fast_forward
                    </i></button>

                <!-- Next button -->
                <button *ngIf="(showNextButton$ | async)" mat-raised-button (click)="routeNext()"
                    [disabled]="(isSavingAnswer$ | async)" [color]="(isPresentation$ | async) ? 'primary' : null">
                    <ng-container *ngIf="isPresentation$ | async; else elseBlock">
                        OK
                    </ng-container>
                    <ng-template #elseBlock>
                        Volgende <i class="material-icons">play_arrow</i>
                    </ng-template>
                </button>

                <!-- OK button -->
                <button *ngIf="(showOkButton$ | async)" [disabled]="(isOkButtonDisabled$ | async)"
                    [attr.tabindex]="(isOkButtonDisabled$ | async) ? '-1' : null" mat-raised-button color="primary"
                    class="answer-button" (click)="clickOk()">
                    <div class="button-content">
                        <ng-container *ngIf="(isSavingAnswer$ | async) !== true">OK</ng-container>
                        <mat-spinner *ngIf="isSavingAnswer$ | async" class="small-spinner" color="accent"
                            [diameter]="26" [strokeWidth]="3"></mat-spinner>
                    </div>
                </button>

                <button class="back-button" (click)="routePrevReadonly()" mat-raised-button color="primary"
                    *ngIf="prevInteractionRouteReadonly$ | async"><mat-icon>keyboard_arrow_left</mat-icon>
                </button>

                <button class="next-button" (click)="routeNextReadonly()" mat-raised-button color="primary"
                    *ngIf="nextInteractionRouteReadonly$ | async"><mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>

        <ng-container *ngIf="(lastUserAttempts$ | async) as lastAttempt">
            <hr />
            <strong>Naam:</strong> {{ lastAttempt.uid | username | async }}<br>
            <strong>Score:</strong> {{ lastAttempt.score | interactionScoreText }}<br>
            <strong>Poging:</strong> {{ lastAttempt.try }}<br>
            <strong>Datum:</strong> {{ lastAttempt.timestamp | date: dateFormat }}
        </ng-container>

        <ng-container *ngIf="(isExamReview$ | async)">
            <hr />
            <strong>Antwoordmodel:</strong>
            <ng-container *ngFor="let answer of (correctReviewAnswers$ | async); let last = last">
                {{ answer }}{{ !last ? ' | ' : '' }}
            </ng-container>
        </ng-container>

        <div *ngIf="(isDevMode$ | async)" class="status-area">
            <hr />
            ID: {{ (parsedInteraction$ | async)?.id }}<br />
            Name: {{ (parsedInteraction$ | async)?.name }}<br />
            UnitID: {{ (unit$ | async)?.id }}<br />
            <!-- Status: {{ (correct$ | async) === null ? '' : (correct$ | async) && 'Correct ✓' || 'Incorrect ✗' }} -->
            UID: {{ (user$ | async)?.uid }}<br />
            <!-- Attempts: {{ userInteractionData?.attempts?.length }} <br /> -->
            <!-- Best score: {{ userInteractionData?.bestScore }} <br /> -->
            Model: {{ (parsedInteraction$ | async)?.model }}<br />
            Vars: {{ (parsedInteraction$ | async)?.vars | json }} <br />
            Options: <span [ngStyle]="{'color': option.correct ? 'green' : 'red'}"
                *ngFor="let option of (parsedInteraction$ | async)?.optionsText">{{ option.option }} | </span><br />
            Events: <span *ngFor="let event of debugEvents">{{ event }} | </span><br />
            Actions: <span *ngFor="let action of (parsedInteraction$ | async)?.actions">{{ action.type + ': ' +
                action.event }} |
            </span><br />
            Version: {{ (parsedInteraction$ | async)?.version }}<br />
            Version date: {{ (parsedInteraction$ | async)?.versionDate }}<br />
            <button class="skip-focus" mat-stroked-button (click)="showParsed()">Parsed</button>
            <button class="skip-focus" mat-stroked-button (click)="showRaw()">Raw</button>
        </div>

    </mat-card>
    <div class="right-side-panel">
        <app-breadcrumb class="breadcrumb" [skipFromBeginning]="1" [pathSuffix]="breadcrumbSuffix"></app-breadcrumb>
        <app-unit-location-tracker *ngIf="unitId$ | async"></app-unit-location-tracker>
        <app-feedback [parsedInteraction$]="parsedInteraction$" [user$]="user$"></app-feedback>
    </div>
</div>