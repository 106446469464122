<div class="bg-image"></div>

<div class="grid-container">
  <div class="breadcrumb-and-nav">
    <div class="breadcrumb"><app-breadcrumb></app-breadcrumb>
    </div>
    <div class="nav-items">
      <ng-container *ngIf="childType !== entityTypes.unit; else appUnits">
        <mat-card [class.last-used-nav-item]="isLastUsedProject(entity, lastUsedLocation$ | async)"
          *ngFor="let entity of entitiesWithProgress$ | async">
          <a class="nav-flex-container" [class.disabled]="entity.disabled"
            [routerLink]="!entity.disabled && [entity.id] || null">
            <img class="entity-icon" alt="icon" *ngIf="entity.icon" [attr.src]="'./assets/icons/' + entity.icon" />
            <h2 class="title" [class]="entity.projectColor ? 'text-' + entity.projectColor : ''">
              {{ displayEntityText(entity) }}
              <span *ngIf="entity.subtitle" class="subtitle"
                [class]="entity.projectColor ? 'text-' + entity.projectColor : ''">
                {{ entity.subtitle }}
              </span>
            </h2>

            <app-progress-indicator [scoreParams]="entity.scoreParams"></app-progress-indicator>
          </a>

          <ng-container *ngIf="lastUsedLocation$ | async as lastUsedLocation">
            <a class="continue-link" *ngIf="isLastUsedProject(entity, lastUsedLocation)"
              [routerLink]="getLastUsedLocationPath(lastUsedLocation)">
              Ga verder
              <i class="material-icons">
                keyboard_double_arrow_right
              </i></a>
          </ng-container>

          <button mat-button class="set-disabled" *ngIf="contentAdminMode$ | async"
            [style.color]="entity.disabled && 'green' || null"
            (click)="entitySetDisabled(entity.id, entity.type, !entity.disabled); false;">
            {{ entity.disabled ? 'Enable' : 'Disable' }}
          </button>
          <button mat-button class="move-in" *ngIf="entity.disabled && contentAdminMode$ | async"
            [routerLink]="[entity.id]">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card>
      </ng-container>

      <ng-template #appUnits>
        <mat-card class="unit-card" *ngFor="let entity of entities$ | async">
          <app-unit [class.disabled]="entity.disabled" [unitId]="entity.id"></app-unit>

          <button mat-button class="set-disabled" *ngIf="contentAdminMode$ | async"
            [style.color]="entity.disabled && 'green' || null"
            (click)="entitySetDisabled(entity.id, entity.type, !entity.disabled); false;">
            {{ entity.disabled ? 'Enable' : 'Disable' }}
          </button>

          <button mat-button class="move-in" *ngIf="entity.disabled && contentAdminMode$ | async"
            [routerLink]="[entity.id + '/presentation/1']">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card>
      </ng-template>
    </div>
  </div>

  <div class="progress">
    <app-progress-area *ngIf="parentScoreParams" [scoreParams]="parentScoreParams"></app-progress-area>
    <app-context></app-context>
  </div>
</div>